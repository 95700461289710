<template>
  <SimpleForm :onSubmit="submit" @done="$router.back()">
    <p class="span-2 form__title">{{ isEdit ? 'Update User' : 'Create New User' }}</p>

    <v-text-field
        v-model="user.name"
        label="Display Name"
        :rules="[required('A display name must be provided')]"
        outlined
        class="span-2"
    />
    <v-text-field
        v-model="user.username"
        label="Username or Email"
        dense
        :rules="[required('Username must be provided')]"
        outlined
        persistent-hint
        hint="Must be a unique email or name"
        class="span-2"
    />
    <v-text-field
        v-model="user.password"
        label="Password"
        :type="showPassword ? 'text' : 'password'"
        dense
        outlined
        v-if="!isEdit"
    />
    <v-text-field
        v-model="confirmPassword"
        label="Confirm Password"
        :rules="[(v) => (v && v === user.password) || 'Passwords does not match']"
        :type="showPassword ? 'text' : 'password'"
        dense
        outlined
        v-if="!isEdit"
    />
    <v-checkbox
        v-model="showPassword"
        label="Show Password"
        style="margin-top: -15px"
        v-if="!isEdit"
    />

    <p class="span-2">Select User Scopes</p>

    <v-treeview
        v-model="user.scopes"
        :items="items"
        selectable
        style="text-align: left"
        class="span-2"
    />

    <loading-dialog v-model="loading" message="Fetching User Data"/>
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import {UsersService} from '@/services/users-service';
import LoadingDialog from '../../components/LoadingDialog';
import {required} from '@/utils/validators';

export default {
  name: 'Form',
  components: {LoadingDialog, SimpleForm},

  data: () => ({
    isEdit: false,
    loading: false,
    showPassword: false,
    service: new UsersService(),
    confirmPassword: '',
    user: {
      scopes: [],
      role: 1
    },

    items: [
      {
        id: 'posts',
        name: 'Posts',
        children: [
          {id: 'posts:new', name: 'Create Posts'},
          {id: 'posts:view', name: 'View Posts'},
          {id: 'posts:edit', name: 'Edit Posts'},
          {id: 'posts:delete', name: 'Delete Posts'},
          {id: 'posts:export', name: 'Export Posts'},
        ]
      },
      {
        id: 'categories',
        name: 'Categories',
        children: [
          {id: 'categories:new', name: 'Create Categories'},
          {id: 'categories:view', name: 'View Categories'},
          {id: 'categories:edit', name: 'Edit Categories'},
        ]
      },
      {
        id: 'sub-categories',
        name: 'Sub-categories',
        children: [
          {id: 'sub-categories:new', name: 'Create Sub-categories'},
          {id: 'sub-categories:view', name: 'View Sub-categories'},
          {id: 'sub-categories:edit', name: 'Edit Sub-categories'},
        ]
      },
      {
        id: 'tags',
        name: 'Tags',
        children: [
          {id: 'tags:new', name: 'Create Tags'},
          {id: 'tags:view', name: 'View Tags'},
          {id: 'tags:edit', name: 'Edit Tags'},
        ]
      },
      {
        id: 'users',
        name: 'Users',
        children: [
          {id: 'users:new', name: 'Create  Users'},
          {id: 'users:view', name: 'View  Users'},
          {id: 'users:edit', name: 'Edit  Users'},
          {id: 'users:delete', name: 'Delete  Users'}
        ]
      },
      {
        id: 'customers',
        name: 'Customers',
        children: [
          {id: 'customers:view', name: 'View Customers'},
          {id: 'customers:show', name: 'Show Customers'},
          {id: 'customers:export', name: 'Export Customers'},
        ]
      },
      {
        id: 'coupons',
        name: 'Coupons',
        children: [
          {id: 'coupons:view', name: 'View Coupons'},
          {id: 'coupons:new', name: 'Create Coupons'},
          {id: 'coupons:delete', name: 'Delete Coupons'}
        ]
      },
      {
        id: 'reports',
        name: 'Reports',
        children: [
          {id: 'reports:view', name: 'View Reports'},
          {id: 'reports:show', name: 'Show Reports'},
          {id: 'reports:delete', name: 'Delete Reports'}
        ]
      },
    ]
  }),

  mounted() {
    this.loadUser();
  },

  methods: {
    required,
    async loadUser() {
      if (!this.$route.query.id) return;
      this.isEdit = true;
      this.loading = true;
      this.user = await this.service.fetchOne(this.$route.query.id);
      this.confirmPassword = this.user.password
      this.loading = false;
    },
    async submit(context) {
      if (this.isEdit) {
        context.changeLoadingMessage('Updating User');
        try {
          await this.service.update(this.user);
          return true
        } catch (e) {
          context.reportError({
            'title': 'Error while creating User',
            'description': e.response.data.error
          })

          return false
        }
      } else {
        context.changeLoadingMessage('Creating A New User');
        try {
          await this.service.create(this.user);
          return true
        } catch (e) {
          console.log(e)
          console.log('error')
          context.reportError({
            'title': 'Error while creating User',
            'description': e?.response?.data?.message || e?.data?.message || 'Some error occurred'
          })

          return false
        }
      }
    }
  }
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}
</style>
