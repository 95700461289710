import axios from 'axios';

export class WebhooksService {
    async fetchAll(userId) {
        return (await axios.get(`/webhooks/all/${userId}`)).data;
    }

    async create(data) {
        return (await axios.post('/webhooks', data)).data;
    }

    async fetchOne(id) {
        return (await axios.get('/webhooks/' + id)).data;
    }

    async update(id, data) {
        return (await axios.patch('/webhooks/' + id, data)).data;
    }

    async delete(url) {
        return (await axios.delete(`/webhooks/${url}`)).data;
    }
}
