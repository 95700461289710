<template>
  <div>
    <data-table
        :edit-handler="getUser() && getUser().scopes.includes('categories:edit') ?  edit  : null"
        :headers="headers"
        :loader="loadData"
        title="Categories"
        @done="$router.back()"
        @add-new="showCategoriesModal = true"
        :allow-add="getUser() ? getUser().scopes.includes('categories:new') : false"
    >
      <template #createdAt="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>

      <template #categoryName="{ item }">
        <tbody>
        <tr>
          <td>
            <v-chip
                class="ma-2"
                color="green"
                small
                text-color="white"
            >
              {{ item.categoryName }}
            </v-chip>
          </td>
        </tr>
        </tbody>
      </template>

      <!--      <template #type="{ item }">-->
      <!--        {{ item.type !== 'Both' ? item.type : 'Residential, Commercial' }}-->
      <!--      </template>-->

      <template #status="{ item }">
        <v-checkbox v-if="item.status === 'Active'" readonly v-model="checked"/>
        <v-checkbox v-else readonly v-model="unchecked" />
      </template>
    </data-table>

    <v-dialog
        v-model="showCategoriesModal"
        width="500"
        @click:outside="closeCategoryForm"
    >
        <SimpleForm :onSubmit="submit" class="my-0" ref="categoryForm">
          <p class="span-2 form__title">{{ isEdit ? 'Update Category' : 'Create New Category' }}</p>

          <v-text-field
              v-model="category.name"
              dense
              :rules="[required('Category Name is required!')]"
              hide-details="auto"
              label="Category Name"
              name="category name"
              outlined
              class="span-2"
          />

          <v-checkbox
              dense
              hide-details="auto"
              label="Active"
              name="status"
              class="span-2"
              v-model="categoryStatus"
          >
          </v-checkbox>

          <loading-dialog v-model="loading" message="Loading..."/>
        </SimpleForm>
    </v-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import DataTable from '../../components/DataTable';
import {getUser} from '../../utils/local';
import {CategoriesService} from "../../services/categories-service";
import LoadingDialog from '../../components/LoadingDialog';
import SimpleForm from '../../components/Form';
import {required} from '../../utils/validators';

export default {
  components: {DataTable, LoadingDialog, SimpleForm},
  data: () => ({
    checked: true,
    unchecked: false,
    items: [],
    loadingDialog: false,
    loading: false,
    service: new CategoriesService(),
    headers: [
      {
        text: 'Category Name',
        value: 'name',
        sortable: true,
      },
      {
        text: 'Active',
        value: 'status',
      },
      {
        text: 'Created At',
        value: 'createdAt'
      },
    ],
    categoryStatus: false,

    selectedCategoryId: '',
    category: {
      name: '',
      status: ''
    },

    selectedCategory: {},

    isEdit: false,
    showCategoriesModal: false,
  }),
  methods: {
    getUser,
    required,
    formatDate(date) {
      return dayjs(date).format('D MMM YYYY - hh:mm a');
    },
    async edit(item) {
      if (!item) return;
      this.selectedCategoryId = item._id;
      this.isEdit = true;
      this.loading = true;
      this.category = await this.service.fetchOne(this.selectedCategoryId);
      this.categoryStatus = this.category.status === 'Active';
      this.loading = false;
      this.showCategoriesModal = true;
    },
    loadData() {
      return this.service.fetchAll();
    },
    closeCategoryForm() {
      document.getElementById('refresh').click();
      this.$refs.categoryForm.resetForm();
      this.showCategoriesModal = false;
      this.isEdit = false;
    },
    async submit(context) {
      if (this.isEdit) {
        context.changeLoadingMessage('Updating Category');
        this.loading = true;
        try {
          this.category.status = this.categoryStatus ? 'Active' : 'Inactive';
          await this.service.update(this.selectedCategoryId, this.category);
          this.closeCategoryForm();
          this.loading = false;
          this.isEdit = false;
          return true
        } catch (e) {
          context.reportError({
            'title': 'Error while Updating Category',
            'description': e?.response?.data?.message || e?.data?.message || 'Some error occurred'
          })
          this.loading = false;
          return false
        }
      } else {
        context.changeLoadingMessage('Creating New Category');
        this.loading = true;
        try {
          this.category.status = this.categoryStatus ? 'Active' : 'Inactive';
          await this.service.create(this.category);
          this.closeCategoryForm();
          this.loading = false;
          return true
        } catch (e) {
          // console.log(e)
          context.reportError({
            'title': 'Error while creating Category',
            'description': e?.response?.data?.message || e?.data?.message || 'Some error occurred'
          })
          this.loading = false;
          return false
        }
      }
    },
  }
};
</script>

<style scoped></style>
