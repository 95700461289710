var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-table',{attrs:{"loader":_vm.loadData,"headers":_vm.headers,"title":"Coupons","allow-add":_vm.getUser() ? _vm.getUser().scopes.includes('coupons:new') : false,"delete-handler":_vm.getUser() && _vm.getUser().scopes.includes('coupons:delete') ?  _vm.service.delete  : null},on:{"add-new":_vm.addNew,"done":function($event){return _vm.$router.back()}},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.coupon.name))])]}},{key:"type",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.coupon.percent_off ? 'Percentage (%)' : 'Amount ($)'))])]}},{key:"discount",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.coupon.percent_off || item.coupon.amount_off / 100))])]}},{key:"duration",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.coupon.duration === 'once' ? 'One Time' : 'Life Time'))])]}},{key:"max_redemptions",fn:function(ref){
var item = ref.item;
return [(item.coupon.max_redemptions)?_c('span',[_vm._v(_vm._s(item.coupon.max_redemptions))]):_c('v-icon',{attrs:{"size":"18"}},[_vm._v("mdi-infinity")])]}},{key:"active",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"readonly":""},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}}])}),_c('loading-dialog',{attrs:{"message":"Loading..."},model:{value:(_vm.loadingDialog),callback:function ($$v) {_vm.loadingDialog=$$v},expression:"loadingDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }