import ReportsView from './ReportsView';
import {getUser} from "../../utils/local";

export const reportsRoutes = [
    getUser()?.scopes.includes('reports:view') ?
    {
        name: 'Reports',
        path: '/reports',
        component: ReportsView
    } : null
];

export const reportsRouter = reportsRoutes.filter(function (x) {
    return x !== null;
})

