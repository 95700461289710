<template>
  <div>
    <v-card class="mx-auto pa-6" max-width="800">
      <v-overlay v-if="dataLoading" :absolute="dataLoading" color="primary" opacity="0.55">
        <v-progress-circular
            indeterminate
            color="#fff"
        ></v-progress-circular>
        <span style="color: #fff; margin-left: 10px"> Please Wait... </span>
      </v-overlay>

      <div v-else>
        <v-card-title style="color: #4173A3;">Customer Details</v-card-title>
        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <v-card-title><h4>Personal Information</h4></v-card-title>
            <v-card-text v-if="customer.name"><b>Name : </b>{{ customer.name }}</v-card-text>
            <v-card-text v-if="customer.username"><b>Email : </b>{{ customer.username }}</v-card-text>
            <v-card-text v-if="customer.phone"><b>Phone : </b>{{ customer.phone }}</v-card-text>
            <v-card-text v-if="customer.organizationName"><b>Organization : </b>{{ customer.organizationName }}
            </v-card-text>
            <v-card-text v-if="customer.verified"><b>Is Verified
              : </b>{{ customer.verified ? 'Verified' : 'Not Verified' }}
            </v-card-text>
            <v-card-text v-if="customer.createdAt "><b> Created At : </b>{{ formatDate(customer.createdAt) }}
            </v-card-text>
          </v-col>

          <v-col v-if="subscription" cols="12" sm="6">
            <v-card-title><h4>Subscription Information</h4></v-card-title>
            <v-card-text v-if="subscription.current_period_start"><b>Subscription Start Date
              : </b>{{ getDateFromTimestamp(subscription.current_period_start) }}
            </v-card-text>
            <v-card-text v-if="subscription.current_period_end"><b>Next Billing Date
              : </b>{{ getDateFromTimestamp(subscription.current_period_end) }}
            </v-card-text>

            <div v-if="plan">
              <v-card-text v-if="plan.id"><b>Plan
                : </b>{{ capitalizeFirstLetter((plan.id).replace('-', ' ')) }}
              </v-card-text>
              <v-card-text v-if="plan.amount"><b>Amount
                : </b>$ {{ (plan.amount / 100).toFixed(2) }}
              </v-card-text>
            </div>

            <div v-if="coupon">
              <div v-if="coupon.name">
                <v-card-text><b>Coupon Promo Code : </b> {{ coupon.name }}</v-card-text>
              </div>

              <div v-if="coupon.percent_off">
                <v-card-text><b>Discount Percentage : </b> {{ coupon.percent_off }} %</v-card-text>
                <v-card-text><b>Discount Amount : </b>
                  $ {{ calculateDiscountAmount(plan.amount, coupon.percent_off) }}
                </v-card-text>
              </div>
              <v-card-text v-else>
                <v-card-text v-if="coupon.amount_off"><b>Discount Amount : </b>
                  $ {{ coupon.amount_off / 100 }}
                </v-card-text>
              </v-card-text>
            </div>

            <v-card-text>
              <span v-if="subscription.status === 'active' && subscription.cancel_at_period_end">
                <b>Subscription Status : </b> {{ capitalizeFirstLetter(subscription.status) }} (in-cancellation)
              </span>

              <span v-else-if="subscription.status !== 'active' && subscription.cancel_at_period_end">
                <b>Subscription Status : </b> {{ capitalizeFirstLetter(subscription.status) }}
              </span>

              <span v-else-if="subscription.status && !subscription.cancel_at_period_end">
                <b>Subscription Status : </b> {{ capitalizeFirstLetter(subscription.status) }}
              </span>

            </v-card-text>

            <v-card-text v-if="subscription.cancel_at"><b>Subscription Cancel at
              : </b>{{ getDateFromTimestamp(subscription.cancel_at) }}
            </v-card-text>
            <v-card-text v-if="subscription.cancel_at_period_end && subscription_cancellation_reason">
              <b>Cancellation Reason : </b>{{ subscription.cancellation_reason }}
            </v-card-text>

          </v-col>
        </v-row>
      </div>
    </v-card>

    <data-table
        v-if="customer.subscription"
        style="max-width: 800px !important;"
        class="px-0 pt-6"
        :loader="getInvoiceDetails"
        :headers="headers"
        title="Invoices Details"
        :allow-add="false"
        :allow-search="false"
    >
      <template #subscription="{item}">
        {{ capitalizeFirstLetter(item.subscription.plan.replace('-', ' ')) }}
      </template>

      <template #amount_paid="{item}">
        $ {{ (item.amount_paid / 100).toFixed(2) }}
      </template>

      <template #status="{item}">
        {{ capitalizeFirstLetter(item.status) }}
      </template>

      <template #period_end="{ item }">
        {{ getDateFromTimestamp(item.period_end) }}
      </template>

      <template #discount="{ item }">
        <span v-if="item.discount && item.discount.coupon">
          {{ item.discount.coupon.name }}
        </span>
        <span v-else>N/A</span>
      </template>

      <template #actions="{ item }">
        <a :href="item.invoice_pdf" v-if="item.invoice_pdf">Preview</a><span v-else>--</span>
      </template>

    </data-table>
  </div>
</template>


<script>
import dayjs from 'dayjs';
import {UsersService} from "../../services/users-service";
import {capitalizeFirstLetter} from "../../utils/local";
import DataTable from '../../components/DataTable';

export default {
  components: {
    DataTable
  },
  data() {
    return {
      dataLoading: true,
      customer: {},
      invoice_details: {},
      service: new UsersService(),

      headers: [
        {
          text: 'Subscription Plan',
          value: 'subscription',
          sortable: false,
        },
        {
          text: 'Amount',
          value: 'amount_paid',
          sortable: false,
        },
        {
          text: 'Status',
          value: 'status',
          sortable: false,
        },
        {
          text:'Invoice Date',
          value: 'period_end',
          sortable: false,
        },
        {
          text:'Applied Coupon',
          value: 'discount',
          sortable: false,
        },
        {
          text:'Action',
          value: 'actions',
          sortable: false,
        },
      ]
    }
  },
  computed: {
    subscription() {
      return this.customer?.subscription;
    },
    plan() {
      return this.subscription?.plan;
    },
    discount() {
      return this.subscription?.discount;
    },
    coupon() {
      return this.discount?.coupon;
    }
  },
  methods: {
    capitalizeFirstLetter,
    formatDate(date) {
      return dayjs(date).format('D MMM YYYY - hh:mm a');
    },
    async loadData() {
      try {
        this.dataLoading = true
        this.customer = await this.service.fetchCustomer(this.$route.query.id);
        this.refreshInvoices();
      } catch (e) {
        console.log(e)
      }
      this.dataLoading = false
    },
    refreshInvoices() {
      if(this.customer.subscription) {
        document.getElementById('refresh').click();
      }
    },
    getDateFromTimestamp(_timestamp) {
      let date = new Date(_timestamp * 1000);
      return this.formatDate(date)
    },

    calculateDiscountAmount(_amount, _percent) {
      return _percent * (_amount / 100);
    },

    async getInvoiceDetails() {
      if(this.customer?.customer?.id) {
        this.invoice_details = await this.service.fetchCustomerInvoices(this.customer.customer.id)
        console.log(this.invoice_details.history)
        return this.invoice_details.history;
      }
    }
  },
  async mounted() {
    await this.loadData()
    console.log(this.customer, 'customer')
  }
}
</script>

<style scoped>
.v-card__text, .v-card__title {
  padding: 10px 16px !important;
}
</style>