<template>
  <v-container>
    <v-tabs v-model="tab" show-arrows>
      <v-tab>Profile</v-tab>
      <v-tab v-if="!user.from_google">Security</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <div class="pa-4">
          <Profile/>
        </div>
      </v-tab-item>
      <v-tab-item v-if="!user.from_google">
        <div class="pa-4">
          <Security/>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import Profile from "@/components/settings/Profile";
import Security from "@/components/settings/Security";

export default {
  name: "Settings",
  components: {Security, Profile},
  data() {
    return {
      tab: null
    }
  },
  computed: {
    user() {
      const token = localStorage.getItem('auth_token');
      let user
      if (token) {
        user = JSON.parse(localStorage.getItem('auth_user'));
      }
      return user
    }
  },
}
</script>

<style scoped>

</style>