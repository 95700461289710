import CustomersView from "@/views/customers/CustomersView";
import CustomerShow from "@/views/customers/CustomerShow";
import {getUser} from "@/utils/local";

export const customersRoutes = [
    getUser()?.scopes.includes('customers:view') ?{
        name: 'Customers',
        path: '/customers',
        component: CustomersView
    } : null,
    // getUser()?.scopes.includes('customers:show') ?{
    {
        name: 'Customer Detail',
        path: '/customer-details',
        component: CustomerShow
    }
    // } : null,
];

export const customersRouter = customersRoutes.filter(function (x) {
    return x !== null;
})
