<template>
  <SimpleForm :onSubmit="submit" @done="reloadPage">
    <p class="span-2 form__title">Update Profile</p>
    <v-text-field
        v-model="user.name"
        label="Name"
        :rules="[required('A name must be provided')]"
        outlined
        class="span-2"
        dense
    />
    <v-text-field
        v-model="user.username"
        label="Email"
        dense
        :rules="[required('Email must be provided')]"
        outlined
        persistent-hint
        hint="Must be a unique email"
        class="span-2"
    />

    <loading-dialog v-model="loading" message="Fetching User Data"/>
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import {UsersService} from '@/services/users-service';
import LoadingDialog from '../../components/LoadingDialog';
import {required} from '@/utils/validators';
import {getUser} from "@/utils/local";

export default {
  name: 'Profile',
  components: {LoadingDialog, SimpleForm},

  data: () => ({
    isEdit: false,
    loading: false,
    showPassword: false,
    service: new UsersService(),
    confirmPassword: '',
    user: {
      scopes: []
    },

  }),

  mounted() {
    this.loadUser();
  },

  methods: {
    required,
    getUser,
    reloadPage() {
      location.reload()
      this.$router.back()
    },
    async loadUser() {
      this.loading = true;
      this.user = getUser();
      this.loading = false;
    },
    async submit(context) {
      this.user.role = 1
      context.changeLoadingMessage('Updating Profile');
      try {
        await this.service.update(this.user);
        const token = localStorage.getItem('auth_token');
        if (token) {
          const user = (await this.$axios.get('/auth/profile')).data;
          localStorage.setItem('auth_user', JSON.stringify(user))
          localStorage.setItem('auth_token', token)
        }

        return true
      } catch (e) {
        context.reportError({
          'title': 'Error while updating profile',
          'description': e?.response?.data?.message || e?.data?.message || 'Some error occured'
        })

        return false
      }
    }
  }
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}
</style>