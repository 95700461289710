<template>
  <SimpleForm :onSubmit="submit" @done="$router.push('/')">
    <p class="span-2 form__title">{{ isEdit ? 'Update Post' : 'Create New Post' }}</p>

    <v-autocomplete
        v-model="post.categories"
        :items="allCategories"
        item-text="name"
        item-value="_id"
        :rules="[requiredArray('At least one category must be selected!')]"
        dense
        @change="filterSubCategory"
        label="Choose Category"
        multiple
        name="Category"
        required
        outlined
        small-chips
    />

    <v-autocomplete
        v-model="post.subcategories"
        :items="subCategories"
        @change="filterTags"
        item-text="name"
        item-value="_id"
        :rules="[requiredArray('At least one subcategory must be selected!')]"
        dense
        label="Choose Subcategory"
        multiple
        name="Subcategory"
        required
        outlined
        small-chips
    />

    <v-autocomplete
        v-model="post.tags"
        :items="tags"
        item-text="name"
        item-value="_id"
        :rules="[requiredArray('At least one tag must be selected!')]"
        dense
        label="Choose Tags"
        multiple
        name="Tags"
        required
        outlined
        class="span-2"
        small-chips
    />

    <v-textarea
        v-model="post.description"
        :rules="[required('Message must be provided')]"
        dense
        label="Enter Message"
        name="Message"
        outlined
        class="span-2"
        style="margin-top: 10px"
    ></v-textarea>

    <v-combobox
        v-model="post.hashtags"
        append-icon=""
        clearable
        dense
        hide-selected
        label="Enter Hashtags"
        multiple
        name="Hashtags"
        small-chips
        class="span-2"
        outlined
    >
    </v-combobox>

    <p>Media</p>

    <v-select
        v-model="post.mediaType"
        :items="mediaTypes"
        @change="updateMediaType"
        :rules="[required('Media Type must be provided')]"
        dense
        label="Choose Media Type"
        required
        class="span-2"
        outlined
    ></v-select>

    <div class="span-2 file-pickers">
      <div v-if="post.mediaType === 'Image'">
        <file-pond
            allow-multiple
            :instantUpload="false"
            :files="uploadedImages"
            label-idle="Drop or Attach Images"
            accepted-file-types="image/*"
            :credits="[]"
            @removefile="imageRemoved"
            @addfile="imageAdded"
            :beforeRemoveFile="confirmFileRemoval"
            :server="{ load: loadFile }"
        />
      </div>

      <file-pond
          v-if="post.mediaType === 'Video'"
          :instantUpload="false"
          :files="uploadedVideos"
          accepted-file-types="video/*"
          label-idle="Drop or Attach Video"
          :credits="[]"
          :beforeRemoveFile="confirmFileRemoval"
          @removefile="videoRemoved"
          @addfile="videoAdded"
          :server="{ load: loadFile }"
      />
    </div>

    <v-checkbox
        v-model="post.isActive"
        dense
        label="Active"
        name="isActive"
        style="margin:0;padding:0"
        class="span-2"
    >
    </v-checkbox>

    <loading-dialog v-model="loading" message="Loading..."/>
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import {PostsService} from "../../services/posts-service";
import LoadingDialog from '../../components/LoadingDialog';
import {required, requiredArray} from '../../utils/validators';

import vueFilePond from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginMediaPreview from 'filepond-plugin-media-preview';
import {storage} from "../../plugins/firebase";

const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview,
    FilePondPluginMediaPreview,
);

async function uploadWithMessage(context, list, message, type) {
  context.changeLoadingMessage(message + ' ...');
  const newList = [];
  for (const item of list) {
    console.log(item)
    const fileName = item.filenameWithoutExtension + '~' + new Date().getTime() + '.' + item.fileExtension
    let reference = storage.ref(type + '/' + fileName);
    let task = reference.put(item.file);
    let thumbnail = null
    await task.then(async () => {
      if (type === 'videos') {
        const thumbnailBlob = await generateThumbnail(item.file)
        console.log(thumbnailBlob, 'blob')
        let thumbRef = storage.ref(type + '/thumbnails/' + fileName);
        let thumbTask = thumbRef.put(thumbnailBlob);
        await thumbTask.then(async () => {
          window.console.log('thumbnail posted')
          thumbnail = {
            url: await storage.ref(type + '/thumbnails/').child(fileName).getDownloadURL(),
          }
        }).catch((e) => window.console.log('uploading image error => ', e))
      }
    }).catch((e) => window.console.log('uploading image error => ', e));

    const media = {
      name: item.filename,
      size: item.fileSize,
      type: item.fileType,
      url: await storage.ref(type).child(fileName).getDownloadURL(),
      isUploaded: true,
      thumbnail
    }
    newList.push(media)
    context.changeLoadingMessage(message + ': ' + item.filenameWithoutExtension);
  }
  return newList;
}

async function generateThumbnail(item) {
  console.log(item)
  console.log('thumbnail')
  const binaryData = []
  binaryData.push(item)
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  const video = document.createElement('video')
  video.setAttribute('src', URL.createObjectURL(new Blob(binaryData)))
  video.load()
  console.log('after onload')
  let thumbnail = await new Promise((resolve) => {
    video.onloadedmetadata = async () => {
      console.log('in onload')
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
      video.currentTime = video.duration / 2
      await video.play()
      context.drawImage(video, 0, 0)
      video.pause()
      const blob = await new Promise((resolve) => {
        return canvas.toBlob(function (blob) {
          resolve(blob)
        })
      })
      console.log(blob)
      resolve(blob)
    }
  })
  console.log(thumbnail, 'thumb')
  return thumbnail
}

export default {
  name: 'Form',
  components: {LoadingDialog, SimpleForm, FilePond},
  computed: {
    uploadedVideos() {
      return (this.post?.media || []).map((image) => {
        return {
          source: image.url,
          options: {type: 'local', metadata: {uploaded: true}}
        };
      });
    },
    uploadedImages() {
      return (this.post?.media || []).map((image) => {
        return {
          source: image.url,
          options: {type: 'local', metadata: {uploaded: true}}
        };
      });
    }
  },
  data: () => ({
    isEdit: false,
    loading: false,
    service: new PostsService(),
    post: {
      categories: [],
      subcategories: [],
      tags: [],
      description: null,
      hashtags: null,
      media: [],
      isActive: true,
      mediaType: 'Image'
    },
    mediaTypes: [
      'Image', 'Video', 'None'
    ],
    categoryTypes: [
      'Residential', 'Commercial', 'Both'
    ],
    categoryType: 'Both',
    allCategories: [],
    subCategories: [],
    allSubCategories: [],
    tags: [],
    allTags: [],

    images: [],
    videos: [],
    mediaToBeDeleted: [],
  }),

  async mounted() {
    this.loading = true;
    await this.loadCategory();
    await this.loadSubcategory();
    await this.loadTags();
    await this.loadPost();
    this.loading = false
  },

  methods: {
    required,
    requiredArray,

    async loadCategory() {
      this.allCategories = await this.service.fetchAllCategories();
    },

    async loadSubcategory() {
      this.allSubCategories = await this.service.fetchAllSubCategories();
      // for storing subCategoriesOptions
      await this.filterSubCategory();
    },

    async loadTags() {
      this.allTags = await this.service.fetchAllTags();
      // for storing tagsOptions
      this.filterTags();
    },

    async loadPost() {
      if (!this.$route.query.id) return;
      this.isEdit = true;
      let response = await this.service.fetchOne(this.$route.query.id);

      // set categories
      this.post.categories = response.categories;

      // Filter subcategories
      this.filterSubCategory();

      // set subcategories
      this.post.subcategories = response.subcategories;

      // Filter tags
      this.filterTags();

      // set tags
      this.post.tags = response.tags;

      // set Others fields
      this.post.description = response.description;
      this.post.hashtags = response.hashtags;
      this.post.media = response.media;
      this.post.isActive = response.isActive;
      this.post.mediaType = response.mediaType;
    },

    filterSubCategory() {
      this.subCategories = this.allSubCategories.filter((subCat) => {
        return subCat.categories.filter((cat) => {
          return this.post.categories.includes(cat._id)
        }).length > 0
      })

      if (this.post.subcategories?.length > 0) {
        this.post.subcategories = this.post.subcategories?.filter((subCategory) => {
          return this.subCategories.filter((subCat) => {
            return subCat._id === subCategory
          }).length > 0
        })
      }

      this.filterTags();
    },

    filterTags() {
      let categories = this.post.categories || [];
      let subCategories = this.post.subcategories || [];

      this.tags = this.allTags.filter((tag) => {
        let matchedCategories = tag.categories.filter((category) => categories.includes(category)).length
        let matchedSubCategories = tag.subcategories.filter((subcategory) => subCategories.includes(subcategory)).length
        return matchedCategories > 0 && matchedSubCategories > 0
      });

      if (this.post.tags?.length > 0) {
        this.post.tags = this.post.tags?.filter((tag) => {
          return this.tags.filter((tg) => {
            return tg._id === tag
          }).length > 0
        })
      }
    },

    async submit(context) {
      if (this.post.mediaType === 'Image') {
        if (this.post.media && this.post.media.length > 0) {
          this.post.media = [
            ...this.post.media,
            ...(await uploadWithMessage(context, this.images, 'Uploading Media', 'images'))
          ];
        } else {
          this.post.media = [
            ...(await uploadWithMessage(context, this.images, 'Uploading Media', 'images'))
          ];
        }
      } else if (this.post.mediaType === 'Video') {
        if (this.post.media && this.post.media.length > 0) {
          this.post.media = [
            ...this.post.media,
            ...(await uploadWithMessage(context, this.videos, 'Uploading Media', 'videos'))
          ];
        } else {
          this.post.media = [
            ...(await uploadWithMessage(context, this.videos, 'Uploading Media', 'videos'))
          ];
        }
      } else {
        this.post.media = []
      }

      if (!this.post.media || this.post.media.length <= 0) {
        this.post.mediaType = 'None'
      }
      if (this.isEdit) {
        context.changeLoadingMessage('Updating Post');
        try {
          let data = {...this.post}

          await this.service.update(this.$route.query.id, data);

          return true

        } catch (e) {
          context.reportError({
            'title': 'Error while Updating Post',
            'description': e?.response?.data?.message || e?.data?.message || 'Some error occurred'
          })

          return false
        }
      } else {
        context.changeLoadingMessage('Creating A New Post');
        try {
          let data = {...this.post}

          await this.service.create(data);

          return true

        } catch (e) {
          context.reportError({
            'title': 'Error while creating Post',
            'description': e?.response?.data?.message || e?.data?.message || 'Some error occurred'
          })
          return false
        }
      }
    },
    videoRemoved(error, file) {
      if (!error && file.getMetadata().uploaded) {
        this.mediaToBeDeleted.push(file.source);
        this.post.media.splice(
            this.post.media.indexOf(file.source),
            1
        );
      }
      this.videos.splice(this.videos.indexOf(file))
    },
    async confirmFileRemoval() {
      return confirm('Warning! This file will be removed. Do you want to proceed?');
    },
    imageRemoved(error, file) {
      if (!error && file.getMetadata().uploaded) {
        this.mediaToBeDeleted.push(file.source);
        this.post.media.splice(
            this.post.media.indexOf(file.source),
            1
        );
      }
      this.images.splice(this.images.indexOf(file))
    },
    clearPond() {
      this.$router.go()
    },
    videoAdded(error, file) {
      if (!error && !file.getMetadata().uploaded) this.videos.push(file);
    },
    imageAdded(error, file) {
      if (!error && !file.getMetadata().uploaded) this.images.push(file);
    },
    async loadFile(source, load, error) {
      await fetch((source.url || source.image_url || source))
          .then((res) => res.blob()).then(load).catch((e) => {
            console.log(e)
            console.log('error')
            error('Failed to load')
          });
    },
    updateMediaType() {
      console.log('update media')
      this.videos = []
      this.images = []
      this.post.media = []
      this.mediaToBeDeleted = [
        ...this.mediaToBeDeleted,
        ...this.post.media
      ]
    },
  }
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}

.file-pickers {
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: auto;
}

.custom-grid {
  display: grid;
  grid-template-columns: 30% 1fr;
  grid-column-gap: 20px;
}
</style>
