<template>
  <div>
    <v-app-bar app dark color="primary" elevate-on-scroll>
      <v-app-bar-nav-icon @click="drawer = !drawer"/>

      <v-card-title>POSTREDI ADMIN</v-card-title>
      <v-spacer/>

      <profile-popup/>
    </v-app-bar>
    <v-main class="main-bg">
      <router-view/>
    </v-main>
    <v-navigation-drawer v-model="drawer" app>
      <img src="../assets/logo.jpg" alt="logo" class="logo" height="70"/>
      <v-divider/>
      <template v-for="(route, key) in routes">
        <v-list-item
            v-if="route.isVisible"
            :key="key"
            class="route"
            color="#da57a7"
            active-class="route--active"
            exact
            dense
            :to="route.to"
        >
          <v-list-item-icon>
            <v-icon v-text="route.icon"/>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="route.title"/>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-else-if="route.isDivider" :key="key" style="margin: 10px 20px"/>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import ProfilePopup from "./ProfilePopup";
import {getUser} from "@/utils/local";
import {required} from "@/utils/validators";

export default {
  name: 'Dashboard',
  components: {ProfilePopup},
  data: () => ({
    drawer: true,
    user: {},
    loading: false,
  }),
  methods: {
    getUser,
    required,
  },
  computed: {
    routes() {
      return [
        {
          to: '/',
          title: 'All Posts',
          icon: 'mdi-view-dashboard-variant',
          isVisible: this.getUser()?.scopes?.includes('posts:view')
        },
        {
          to: '/categories',
          title: 'Categories',
          icon: 'mdi-book-multiple',
          isVisible: this.getUser()?.scopes?.includes('categories:view')
        },
        {
          to: '/sub-categories',
          title: 'Sub Categories',
          icon: 'mdi-book-multiple',
          isVisible: this.getUser()?.scopes?.includes('sub-categories:view')
        },
        {
          to: '/tags',
          title: 'Tags',
          icon: 'mdi-tag',
          isVisible: this.getUser()?.scopes?.includes('tags:view')
        },
        {
          to: '/customers',
          title: 'Customers',
          icon: 'mdi-account-multiple',
          isVisible: this.getUser()?.scopes?.includes('customers:view')
        },
        {
          to: '/coupons',
          title: 'Coupons',
          icon: 'mdi-tag',
          isVisible: this.getUser()?.scopes?.includes('coupons:view')
        },
        {
          to: '/reports',
          title: 'Reports',
          icon: 'mdi-clipboard',
          isVisible: this.getUser()?.scopes?.includes('reports:view')
        },
        {
          to: '/users',
          title: 'Users',
          icon: 'mdi-account-multiple',
          isVisible: this.getUser()?.scopes?.includes('users:view')
        },
      ];
    }
  }
};
</script>

<style lang="sass" scoped>
.logo
  display: block
  margin: 5px auto
  border-radius: 10px

.route
  margin: 5px
  text-align: left
  overflow: hidden
  border-radius: 4px
  font-size: 20px !important
  font-family: "Roboto", sans-serif

  i
    font-size: 20px

  &--active i
    color: inherit

  &--active
    color: #495db7

.main-bg
  background: linear-gradient(to bottom, #1976d2 300px, white 300px)
</style>
