var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('data-table',{attrs:{"loader":_vm.loadData,"headers":_vm.headers,"title":"Customers","allow-add":false,"view-handler":_vm.showCustomer},on:{"done":function($event){return _vm.$router.back()}},scopedSlots:_vm._u([{key:"primary-action",fn:function(){return [(_vm.getUser() ? _vm.getUser().scopes.includes('customers:export') : false)?_c('v-btn',{staticClass:"mr-3",attrs:{"elevation":"0","color":"primary"},on:{"click":_vm.exportCustomers}},[_vm._v("Export ")]):_vm._e(),_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"v-btn__pre-icon",attrs:{"small":""}},[_vm._v("mdi-filter")]),_vm._v("  Status ")],1)]}}])},[_c('v-list',_vm._l((_vm.status_filters_option),function(status,key){return _c('v-list-item',{key:key},[_c('v-list-item-title',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.applyFilterOnStatus(status.value)}}},[_vm._v(_vm._s(status.text)+" ")])],1)}),1)],1)],1)]},proxy:true},{key:"name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-start"},[(item.image)?_c('v-avatar',{staticStyle:{"border":"1px solid #EF3736"},attrs:{"size":"42"}},[(item.image.name.includes('http'))?_c('img',{staticStyle:{"max-width":"100%"},attrs:{"src":item.image.name,"referrerpolicy":"no-referrer"}}):_c('img',{attrs:{"src":_vm.$axios.defaults.baseURL + 'uploads/' + item.image.name}})]):_c('v-avatar',{staticClass:"white--text",staticStyle:{"border":"1px solid #EF3736"},attrs:{"size":"42","color":"primary"}},[_c('span',[_vm._v(_vm._s(_vm.resolveInitial((item.name || ''))))])]),_c('p',{staticClass:"ma-0 ml-2"},[_vm._v(_vm._s((item.name || '')))])],1)]}},{key:"phone",fn:function(ref){
var item = ref.item;
return [(item.phone)?_c('span',[_vm._v(_vm._s(item.phone))]):_c('span',[_vm._v("N/A")])]}},{key:"subscription",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getSubscriptionStatus(item))+" ")])]}},{key:"created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }