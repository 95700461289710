import firebase from "firebase/app";
import 'firebase/auth';
import "firebase/firestore";
import "firebase/storage";


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyD9NRE6bRuyZPgMnE0OzKrjx5W8Ffi0tZI",
    authDomain: "postredi.firebaseapp.com",
    projectId: "postredi",
    storageBucket: "postredi.appspot.com",
    messagingSenderId: "174903613959",
    appId: "1:174903613959:web:bfcb8f5f806e2852b668a2"
};


firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export {
    storage
}
