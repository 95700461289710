export function getUser() {
    const token = localStorage.getItem('auth_token');
    return token ? JSON.parse(localStorage.getItem('auth_user')) : null;
}
export function resolveInitial(item) {
    if (item && item !== ' ') {
        const names = item.split(" ");
        let initials = "";
        for (let i = 0; i < names.length; i++) {
            if (i < 2 && names[i] !== "") {
                initials += names[i][0];
            }
        }
        return initials.toUpperCase();
    } else {
        return "--";
    }
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}