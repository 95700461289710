import UserForm from './UserForm';
import UsersView from './UsersView';
import {getUser} from "@/utils/local";

export const usersRoutes = [
    getUser()?.scopes.includes('users:view') ? {
        name: 'Users',
        path: '/users',
        component: UsersView
    } : null,
    (getUser()?.scopes.includes('users:new') || getUser()?.scopes.includes('users:edit')) ? {
        name: 'NewUser',
        path: '/user',
        component: UserForm
    } : null,
];

export const usersRouter = usersRoutes.filter(function (x) {
    return x !== null;
})

