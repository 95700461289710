import PostsForm from './PostForm';
import PostsView from './PostsView';
import {getUser} from "@/utils/local";

export const postsRoutes = [
    getUser()?.scopes.includes('posts:view') ? {
        name: 'Posts',
        path: '/',
        component: PostsView
    } : null,
    (getUser()?.scopes.includes('posts:new') || getUser()?.scopes.includes('posts:edit')) ? {
        name: 'NewPost',
        path: '/post',
        component: PostsForm
    } : null,
];

export const postsRouter = postsRoutes.filter(function (x) {
    return x !== null;
})

