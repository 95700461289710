import axios from 'axios';

export class CouponsService {
    async fetchAll() {
        return (await axios.get('/stripe/coupons')).data.filter((o) => o.active);
    }

    async create(coupon) {
        return (await axios.post('/stripe/coupons', coupon)).data;
    }

    async delete(coupon) {
        return (await axios.delete('/stripe/coupons/' + coupon.coupon.id)).data;
    }
}
