<template>
  <div>
    <data-table
        :loader="loadData"
        :headers="headers"
        title="Users"
        :allow-add="getUser() ? getUser().scopes.includes('users:new') : false"
        @add-new="addNew"
        :edit-handler="getUser() && getUser().scopes.includes('users:edit') ?  edit  : null"
        :delete-handler="getUser() && getUser().scopes.includes('users:delete') ?  service.delete  : null"
        @done="$router.back()"
    >

      <template #createdAt="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>

      <template #scopes="{ item }">
        {{ item.scopes.join(', ').substr(0, 80) + '...' }}
      </template>

      <template #extra-actions="{item}">
        <v-icon
            v-if="getUser() && getUser().scopes.includes('users:edit')"
            small
            class="mr-1"
            @click="openChangePassword(item)"
        >
          mdi-lock
        </v-icon>
      </template>
    </data-table>
    <v-dialog v-model="changePassword" width="400">
      <v-card style="padding: 40px">
        <v-form ref="changePassword">
          <p class="span-2 form__title mb-0">Change Password</p>
          <p class="span-2 mb-6">This will change password for this admin.</p>
          <v-text-field
              v-model="password"
              label="Password"
              :type="showPassword ? 'text' : 'password'"
              :rules="[required()]"
              dense
              outlined
          />
          <v-text-field
              v-model="confirmPassword"
              label="Confirm Password"
              :rules="[(v) => (v && v === password) || 'Passwords does not match']"
              :type="showPassword ? 'text' : 'password'"
              dense
              outlined
          />
          <v-checkbox
              v-model="showPassword"
              label="Show Password"
              style="margin-top: -15px"
          />
          <v-btn
              style="width: 100%"
              elevation="0"
              color="primary"
              @click="changePasswordSubmit"
          >Submit
          </v-btn>
        </v-form>
      </v-card>
    </v-dialog>
    <loading-dialog v-model="loadingDialog" message="Loading..."/>
    <ErrorDialog v-model="error" :error="errorVal"/>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import DataTable from '../../components/DataTable';
import {getUser} from '@/utils/local';
import {UsersService} from "@/services/users-service";
import LoadingDialog from "@/components/LoadingDialog";
import ErrorDialog from "@/components/ErrorDialog";
import {required} from "@/utils/validators";


export default {
  components: {ErrorDialog, LoadingDialog, DataTable},

  data: () => ({
    items: [],
    loading: false,
    service: new UsersService(),

    user: null,
    loadingDialog: false,
    error: false,
    errorVal: {},
    changePassword: false,
    password: null,
    confirmPassword: null,
    showPassword: false,

    headers: [
      {
        text: 'Display Name',
        value: 'name',
        sortable: false,
        width: 150
      },
      {
        text: 'Email',
        value: 'username',
        sortable: false,
        width: 200
      },
      {
        text: 'Scopes',
        value: 'scopes',
        sortable: false
      },
      {
        width: 180,
        text: 'Created At',
        value: 'createdAt'
      }
    ]
  }),

  methods: {
    getUser,
    required,
    formatDate(date) {
      return dayjs(date).format('D MMM YYYY - hh:mm a');
    },

    addNew() {
      this.$router.push('/user');
    },
    edit(item) {
      this.$router.push(`/user?id=${item._id}`);
    },
    async loadData() {
      return await this.service.fetchAllAdmins();
    },
    async changePasswordSubmit() {
      if (this.$refs.changePassword.validate()) {
        this.user.password = this.password
        try {
          this.loadingDialog = true
          await this.service.update(this.user);
          this.password = ''
          this.confirmPassword = ''
          this.changePassword = false
          this.$refs.changePassword.reset()
          this.user = null
        } catch (e) {
          this.errorVal = {
            'title': 'Error while changing password',
            'description': e?.response?.data?.message || e?.data?.message || 'Some error occured'
          }
          this.error = true
          this.loadingDialog = false
        }
        this.loadingDialog = false
      }
    },
    openChangePassword(item) {
      this.user = item
      this.changePassword = true
    },
  }
};
</script>

<style scoped></style>