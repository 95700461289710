import axios from 'axios';

export class UsersService {
    async fetchAllAdmins() {
        return (await axios.get('persons/admins')).data;
    }

    async fetchOne(id) {
        return (await axios.get('persons/' + id)).data;
    }

    async update(user) {
        return (await axios.patch('persons/' + user._id, user)).data;
    }

    async create(user) {
        return (await axios.post('/auth/sign-up', user)).data;
    }

    async changePassword(data, id) {
        return (await axios.patch('persons/update-password/' + id, data)).data;
    }

    async fetchAllCustomers() {
        return (await axios.get('/persons')).data;
    }

    async fetchCustomer(_id) {
        return (await axios.get('/persons/' + _id)).data;
    }

    async fetchCustomerInvoices(_id) {
        return (await axios.get('/stripe/customer-invoices/' + _id)).data;
    }

    async delete(item) {
        return (await axios.delete('persons/' + item._id)).data;
    }

    async signOut() {
        return (await axios.post('auth/sign-out')).data;
    }

}
