import Vue from 'vue';
import VueRouter from 'vue-router';
import SignInView from './views/auth/SignIn';
import NotFound from './views/404.vue';
import Dashboard from './components/Dashboard';
import {usersRouter} from './views/users/router';
import {postsRouter} from "./views/posts/router";
import {reportsRouter} from "./views/reports/router";
import Settings from "./views/auth/Settings";
import {couponsRouter} from "./views/coupons/router";
import {customersRouter} from "./views/customers/router";
import {categoriesRouter} from "./views/categories/router";
import {subCategoriesRouter} from "./views/sub_categories/router";
import {tagsRouter} from "./views/tags/router";
import Maintenance from "./views/maintenance";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: Dashboard,
        children: [
            {
                path: '/auth/settings',
                component: Settings
            },
            ...usersRouter,
            ...postsRouter,
            ...reportsRouter,
            ...customersRouter,
            ...couponsRouter,
            ...categoriesRouter,
            ...subCategoriesRouter,
            ...tagsRouter,
        ]
    },
    {
        name: 'MaintenanceMode',
        path: '/maintenance',
        component: Maintenance
    },
    {
        name: 'SignIn',
        path: '/auth/sign-in',
        component: SignInView
    },
    {
        name: 'NotFound',
        path: '**',
        component: NotFound
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, __, next) => {
    if (!localStorage.getItem('auth_token')) {
        if (to.path !== '/auth/sign-in') {
            next('/auth/sign-in');
        }
    }

    next();
});

export default router;
