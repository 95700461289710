import axios from 'axios';

export class TagsService {
    async fetchAllActiveTags(_data) {
        return (await axios.get('/tag/filter?status=Active', {params: _data})).data;
    }

    async fetchAllCategories() {
        return (await axios.get('/category?status=Active')).data;
    }

    async fetchAllSubCategories() {
        return (await axios.get('/subcategory/filter?status=Active')).data;
    }

    async fetchAll() {
        return (await axios.get('/tag/filter')).data;
    }

    async fetchOne(id) {
        return (await axios.get('/tag/' + id)).data;
    }

    async update(id, data) {
        return (await axios.patch('/tag/' + id, data)).data;
    }

    async create(data) {
        return (await axios.post('/tag', data)).data;
    }

    async delete(item) {
        return (await axios.delete('/tag/' + item._id)).data;
    }
}
