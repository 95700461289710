<template>
  <div>
    <data-table
        :edit-handler="getUser() && getUser().scopes.includes('categories:edit') ?  edit  : null"
        :headers="headers"
        :loader="loadData"
        title="Sub Categories"
        @done="$router.back()"
        @add-new="openSubCategoriesForm"
        :allow-add="getUser() ? getUser().scopes.includes('categories:new') : false"
    >

      <template #categories="{ item }">
            <v-chip
                v-for="(category,key) in item.categories"
                :key="key"
                class="ma-1"
                color="primary"
                small
                text-color="white"
            >
              {{ category.name }}
            </v-chip>
      </template>

      <template #status="{ item }">
        <v-checkbox v-if="item.status === 'Active'" readonly v-model="checked"/>
        <v-checkbox v-else readonly v-model="unchecked" />
      </template>

      <template #createdAt="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>
    </data-table>

    <v-dialog
        v-model="showSubCategoriesModal"
        width="500"
        @click:outside="closeSubCategoryForm"
    >
        <SimpleForm :onSubmit="submit" ref="subCategoryForm" class="my-0">
          <p class="span-2 form__title">{{ isEdit ? 'Update Subcategory' : 'Create New Subcategory' }}</p>

          <v-text-field
              v-model="sub_category.name"
              dense
              :rules="[required('Subcategory Name is required!')]"
              label="Subcategory Name"
              name="sub category name"
              outlined
              class="span-2"
          />

          <v-autocomplete
              :items="categories"
              v-model="sub_category.categories"
              item-text="name"
              item-value="_id"
              dense
              :rules="[requiredArray('At least one category must be selected!')]"
              hide-details="auto"
              class="flex-grow-1 span-2"
              :loading="loading"
              label="Choose Categories"
              multiple
              name="Category"
              outlined
              small-chips
          ></v-autocomplete>


          <v-checkbox
              dense
              hide-details="auto"
              label="Active"
              name="status"
              class="span-2"
              v-model="subCategoryStatus"
          >
          </v-checkbox>

          <loading-dialog v-model="loading" message="Loading..."/>
        </SimpleForm>
    </v-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import DataTable from '../../components/DataTable';
import {getUser} from '../../utils/local';
import {SubCategoriesService} from "../../services/sub-categories-service";
import LoadingDialog from '../../components/LoadingDialog';
import SimpleForm from '../../components/Form';
import {required,requiredArray} from '../../utils/validators';

export default {
  components: {DataTable, LoadingDialog, SimpleForm},
  data: () => ({
    checked: true,
    unchecked: false,
    items: [],
    loadingDialog: false,
    loading: false,
    service: new SubCategoriesService(),
    headers: [
      {
        text: 'Subcategory',
        value: 'name',
        sortable: true,
      },
      {
        text: 'Category',
        value: 'categories',
        sortable: true,
      },
      {
        text: 'Active',
        value: 'status',
      },
      {
        text: 'Created At',
        value: 'createdAt'
      },
    ],

    categories: [],
    subCategoryStatus: false,
    selectedSubCategoryId: '',
    sub_category: {
      name: '',
      categories: [],
      status: ''
    },

    isEdit: false,
    showSubCategoriesModal: false,
  }),

  // watch: {
  //   selectedSubCategories: {
  //     handler(value) {
  //       this.sub_category.categories = value.map((sub_cate) => {
  //        return this.categories.find((category) => {
  //           return category._id === sub_cate;
  //         })
  //       })
  //     }, deep: true
  //   }
  // },
  methods: {
    getUser,
    required,
    requiredArray,
    formatDate(date) {
      return dayjs(date).format('D MMM YYYY - hh:mm a');
    },
    async edit(item) {
      if (!item) return;
      this.selectedSubCategoryId = item._id;
      this.isEdit = true;
      this.loading = true;
      this.sub_category = await this.service.fetchOne(this.selectedSubCategoryId);
      this.subCategoryStatus = this.sub_category.status === 'Active';
      this.loading = false;
      this.showSubCategoriesModal = true;
    },
    async loadData() {
      this.categories = await this.service.fetchAllCategories();
      return this.service.fetchAll();
    },
    async openSubCategoriesForm() {
      this.showSubCategoriesModal = true;
    },

    closeSubCategoryForm() {
      document.getElementById('refresh').click();
      this.$refs.subCategoryForm.resetForm();
      this.showSubCategoriesModal = false;
      this.isEdit = false;
    },

    async submit(context) {

      if (this.isEdit) {
        context.changeLoadingMessage('Updating Subcategory');
        this.loading = true;
        try {
          this.sub_category.status = this.subCategoryStatus ? 'Active' : 'Inactive';
          await this.service.update(this.selectedSubCategoryId, this.sub_category);
          this.closeSubCategoryForm();
          this.loading = false;
          this.isEdit = false
          return true
        } catch (e) {
          context.reportError({
            'title': 'Error while Updating Subcategory',
            'description': e?.response?.data?.message || e?.data?.message || 'Some error occurred'
          })
          this.loading = false;
          return false
        }
      } else {
        context.changeLoadingMessage('Creating New Subcategory');

        this.loading = true;
        try {
          this.sub_category.status = this.subCategoryStatus ? 'Active' : 'Inactive';
          await this.service.create(this.sub_category);
          this.closeSubCategoryForm();
          this.loading = false;
          return true
        } catch (e) {
          // console.log(e)
          context.reportError({
            'title': 'Error while creating Subcategory',
            'description': e?.response?.data?.message || e?.data?.message || 'Some error occurred'
          })
          this.loading = false;
          return false
        }
      }
    },
  }
};
</script>

<style scoped>
.invalid {
  border: 1px red solid !important;
}
</style>
