import axios from 'axios';

export class PostsService {
  async fetchAllFiltered(data) {
    console.log(data, 'filter')
    console.log((await axios.post('/post-template/filter', data)).data)
    return (await axios.post('/post-template/filter', data)).data;
  }

  async fetchAllCategories() {
    return (await axios.get('/category?status=Active')).data;
  }

  async fetchAllSubCategories() {
    return (await axios.get('/subcategory/filter?status=Active')).data;
  }

  async fetchAllTags() {
    return (await axios.get('/tag/filter?status=Active')).data;
  }

  async fetchAll() {
    return (await axios.get('/post-template')).data;
  }

  async fetchOne(id) {
    return (await axios.get('/post-template/' + id)).data;
  }

  async update(id, data) {
    return (await axios.patch('/post-template/' + id, data)).data;
  }

  async create(data) {
    return (await axios.post('/post-template', data)).data;
  }

  async delete(item) {
    return (await axios.delete('/post-template/' + item._id)).data;
  }

  async deleteFile(id) {
    return (await axios.delete('/post-template/delete-file/' + id)).data;
  }

}
