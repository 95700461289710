import CouponsView from "@/views/coupons/CouponsView";
import CouponForm from "@/views/coupons/CouponForm";
import {getUser} from "@/utils/local";

const couponsRoutes = [
    getUser()?.scopes.includes('coupons:view') ? {
        name: 'Coupons',
        path: '/coupons',
        component: CouponsView
    } : null,
    getUser()?.scopes.includes('coupons:new') ? {
        name: 'Coupon',
        path: '/coupon',
        component: CouponForm
    } : null
];

export const couponsRouter = couponsRoutes.filter(function (x) {
    return x !== null;
})
