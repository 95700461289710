import SubCategoriesView from "./SubCategoriesView";
import {getUser} from "../../utils/local";

export const subCategoriesRoutes = [
    getUser()?.scopes.includes('categories:view') ? {
    path: '/sub-categories',
        name: 'SubCategories',
        component: SubCategoriesView
    } : null
];

export const subCategoriesRouter = subCategoriesRoutes.filter(function (x) {
    return x !== null;
})

