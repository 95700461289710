<template>
  <div>
    <data-table
        :allow-add="false"
        :delete-handler="service.delete"
        :edit-handler="null"
        :headers="headers"
        :loader="loadData"
        :view-handler="getUser() && getUser().scopes.includes('reports:show') ? showReport  : null"
        title="Reports"
        @done="$router.back()"
    >
      <template #primary-action>
        <v-btn
            color="primary"
            @click="openUrlDialog"
        >
          Add Urls
        </v-btn>
      </template>
      <template #type="{ item }">
        {{ capitalizeFirstLetter(item.type) }}
      </template>
      <template #comment="{item}">
        {{ item.comment.substr(0, 50) + '...' }}
      </template>
      <template #createdAt="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>
    </data-table>

    <!--    show details-->
    <v-dialog v-model="dialog" width="800">
      <v-card v-if="selectedItem" class="pa-8">

        <div class="font-weight-bold text-h6">
          Report Details
        </div>

        <div class="font-weight-bold mt-3 mb-1">Email</div>
        <div>{{ selectedItem.email }}</div>

        <div v-if="selectedItem.phone !== 'N/A'">
          <div class="font-weight-bold mt-3 mb-1">Phone</div>
          <div>{{ selectedItem.phone }}</div>
        </div>

        <div class="font-weight-bold mt-3 mb-1">Type</div>
        <div>{{ capitalizeFirstLetter(selectedItem.type) }}</div>

        <div class="font-weight-bold mt-3 mb-1">Feedback</div>
        <p class="text-justify">{{ selectedItem.comment }}</p>

        <div v-if="selectedItem.reproduction_steps" class="font-weight-bold mt-3 mb-1">Steps to reproduce</div>
        <p v-if="selectedItem.reproduction_steps" class="text-justify">
          {{ selectedItem.reproduction_steps }}
        </p>
        <v-row>
          <v-col v-for="(item,index) in selectedItem.media" :key="index"
                 class="col-6 col-sm-4 col-md-3 d-flex flex-col items-center ">
            <div class="d-flex flex-col items-center mediaCol">

              <div v-if="item.type==='image'" class="d-flex justify-center"
                   style="background-color:white; width:100%;"
                   @click="openInNewTab(item.url)">
                <img :src="item.url" alt="Media Image" class="image" loading="lazy" style="object-fit: cover"/>
              </div>

              <div v-if="item.type==='video'" class="d-flex justify-center"
                   style="background-color:white; width:100%; position:relative;" @click="openInNewTab(item.url)">
                <img :src="item.thumbnailUrl" alt="Video Thumbnail" class="image" loading="lazy"
                     style="object-fit: cover"/>
                <div style="width: 100%;height: 100%;background: rgba(0,0,0,0.3); position: absolute; z-index: 5"></div>
                <img alt="" class="play-icon" src="../../assets/play-icon.png">
              </div>

            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!--    add url dialog-->
    <v-dialog v-model="showUrlDialog" :width="$vuetify.breakpoint.xl ? '60%' : '90%'">
      <v-card class="pa-4">
        <v-row>
          <v-col cols="12">
            <div class="d-flex justify-space-between align-center">
              <h3>Add Url</h3>
              <v-avatar class="pointer" @click="closeUrlDialog">
                <v-icon>mdi-close</v-icon>
              </v-avatar>
            </div>
          </v-col>
          <v-col class="py-0" cols="12">
            <div style="width: 50%">
              <v-form ref="urlForm" class="d-flex">
                <v-text-field
                    v-model="url"
                    :rules="[required()]"
                    dense
                    hide-details="auto"
                    placeholder="Please enter Zapier webhook url"
                    label="Url"
                    outlined
                />
                <v-btn
                    class="ml-2"
                    color="primary"
                    @click="saveUrl"
                >
                  {{ isEdit ? 'Save' : 'Add' }}
                </v-btn>
              </v-form>
            </div>
          </v-col>

          <v-col cols="12">
            <v-data-table
                :headers="urlHeaders"
                :height="$vuetify.breakpoint.mdAndUp ? '300' : '200'"
                :items="urlItems"
                :items-per-page="5"
                dense
                fixed-header
                mobile-breakpoint="1000"
            >
              <template #createdAt="{ item }">
                {{ formatDate(item.createdAt) }}
              </template>

              <template #item.actions="{ item }">
                <v-icon
                    color="green"
                    small
                    @click="selectItem(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                    color="red"
                    small
                    @click="onDelete(item)"
                >
                  mdi-delete
                </v-icon>
              </template>


            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <loading-dialog v-model="loadingDialog" message="Loading..."/>
    <ErrorDialog v-model="error" :error="errorVal"/>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import {capitalizeFirstLetter, getUser} from "@/utils/local";
import DataTable from '@/components/DataTable';
import LoadingDialog from "@/components/LoadingDialog";
import ErrorDialog from "@/components/ErrorDialog";
import {ReportsService} from "@/services/reports-service";
import {WebhooksService} from "../../services/webhooks-service";
import {required} from "../../utils/validators";

export default {
  components: {ErrorDialog, LoadingDialog, DataTable},

  data: () => ({
    items: [],
    selectedItem: null,
    loading: false,
    service: new ReportsService(),
    dialog: false,
    user: null,
    loadingDialog: false,
    error: false,
    errorVal: {},
    changePassword: false,
    password: null,
    confirmPassword: null,
    showPassword: false,

    headers: [
      {
        text: 'Email',
        value: 'email',
        sortable: true,
        width: 200
      },
      {
        text: 'Phone',
        value: 'phone',
        sortable: true,
      },
      {
        text: 'Type',
        value: 'type',
        sortable: true
      },
      {
        text: 'Feedback',
        value: 'comment',
        sortable: false,
      },
      {
        text: 'Created At',
        value: 'createdAt',
        width: 180,
        sortable: true
      }
    ],

    // Url Dialog
    webhookService: new WebhooksService(),
    showUrlDialog: false,
    urlHeaders: [
      {
        text: 'URL',
        value: 'url',
        sortable: false,
      },
      {
        text: 'Created At',
        value: 'createdAt',
        sortable: true
      },
      {
        text: 'Actions', value: 'actions', sortable: false
      },
    ],
    selectedUrlItem: null,
    urlItems: [],
    isEdit: false,
    url: '',

  }),

  methods: {
    getUser,
    required,
    capitalizeFirstLetter,
    async onDelete(item) {
      console.log('item', item);
      this.loadingDialog = true;
      if (confirm('Are you sure you want to delete this item?')) {
        try {
          await this.webhookService.delete(encodeURIComponent(item.url));
          await this.loadUrlsData();
        } catch (e) {
          console.log('error in deletion', e)
          this.loadingDialog = false;
          this.errorVal = {
            title: 'Error while deleting data',
            description: e.data.error || e.data.message || 'Some Error occurred'
          }
          this.error = true;
        }
      }
    },
    async loadUrlsData() {
      this.loadingDialog = true;
      try {
        this.urlItems = await this.webhookService.fetchAll(this.getUser()._id);
        this.loadingDialog = false;
      } catch(err) {
        console.log('error in loading', err)
        this.loadingDialog = false;
        this.errorValue = {
          title: 'Error while loading data',
          description: err.data.error || err.data.message || 'Some Error occurred'
        }
        this.error = true;
      }
    },
    closeUrlDialog() {
      this.isEdit = false;
      this.selectedUrlItem = null;
      this.url = null;
      this.$refs.urlForm.reset();
      this.showUrlDialog = false;
    },
    async saveUrl() {
      if (this.$refs.urlForm.validate()) {

        const _data = {
          admin: this.getUser()._id,
          url: this.url
        }

        this.loadingDialog = true;

        if (this.isEdit) {
          try {
            await this.webhookService.update(encodeURIComponent(this.selectedUrlItem.url), _data);
            this.$refs.urlForm.reset();
            this.url = null;
            this.loadingDialog = false;
          } catch (err) {
            console.log('error in updating', err)
            this.loadingDialog = false;
            this.errorValue = {
              title: 'Error while loading data',
              description: err.data.error || err.data.message || 'Some Error occurred'
            }
            this.error = true;
          }

        } else {

          try {
            let res = await this.webhookService.create(_data);
            console.log('res', res);
            this.loadingDialog = false;
            this.$refs.urlForm.reset();
            this.url = null;
          } catch (err) {
            console.log('error in creating', err)
            this.loadingDialog = false;
            this.errorValue = {
              title: 'Error while loading data',
              description: err?.response?.data?.error ?? 'Some Error occurred'
            }
            this.error = true;
          }
        }

        await this.loadUrlsData();
      }
    },
    selectItem(item) {
      this.isEdit = true;
      this.selectedUrlItem = item;
      this.url = item.url;

    },
    openUrlDialog() {
      this.showUrlDialog = true;
      this.loadUrlsData();
    },
    formatDate(date) {
      return dayjs(date).format('D MMM YYYY - hh:mm a');
    },
    showReport(item) {
      this.selectedItem = item
      this.dialog = true;
    },

    openInNewTab(url) {
      window.open(url, '_blank').focus();
    },

    async loadData() {
      let data = await this.service.fetchAll();
      console.log('reports', data);
      data = data.map((report) => {
        if (!report.phone) {
          report.phone = 'N/A'
        }
        report.media = report.media.map((media) => {
          const images = ["jpeg", "png", 'jpg', "gif", "heic"]
          const videos = ["hevc", "mp4", "mov"]

          let shortLink = media.url.split("?alt")[0]
          let parts = shortLink.split(".");
          let extension = parts[parts.length - 1];

          if (images.includes(extension)) {
            media.type = "image"
          } else if (videos.includes(extension)) {
            media.type = "video"

            let parts = media.url.split('%2F');
            media.thumbnailUrl = parts[0] + '%2Fthumbnails%2F' + parts[1].split('?alt').join('.jpg?alt');
          }

          return media
        })
        return report
      })
      return data
    },
  }
}
</script>

<style scoped>

.mediaCol {
  background-color: #1976D2;
  max-height: fit-content;
  cursor: pointer;
  border-width: 2px;
  border-style: solid;
  border-color: white;
}

.image {
  height: 170px;
  width: 100%;
  z-index: 2;
}

.mediaCol:hover {
  box-shadow: 0 0 5px #1976D2;
}

.play-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 35%;
  width: 35%;
  object-fit: contain;
  z-index: 10;
}

</style>