<template>
  <div>
    <data-table
        :edit-handler="getUser() && getUser().scopes.includes('tags:edit') ?  edit  : null"
        :headers="headers"
        :loader="loadData"
        title="Tags"
        @done="$router.back()"
        @add-new="openSubCategoriesForm"
        :allow-add="getUser() ? getUser().scopes.includes('tags:new') : false"
    >

      <template #categories="{ item }">
        <v-chip
            v-for="(category,key) in item.categories"
            :key="key"
            class="ma-1"
            color="primary"
            small
            text-color="white"
        >
          {{ categoryName(category) }}
        </v-chip>
      </template>

      <template #subcategories="{ item }">
        <v-chip
            v-for="(subcategory,key) in item.subcategories"
            :key="key"
            class="ma-1"
            color="primary"
            small
            text-color="white"
        >
          {{ subCategoryName(subcategory) }}
        </v-chip>
      </template>

      <template #status="{ item }">
        <v-checkbox v-if="item.status === 'Active'" readonly v-model="checked"/>
        <v-checkbox v-else readonly v-model="unchecked"/>
      </template>

      <template #createdAt="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>
    </data-table>

    <v-dialog
        v-model="showTagModal"
        width="500"
        @click:outside="closeTagForm"
    >
      <SimpleForm :onSubmit="submit" ref="tagForm" class="my-0">
        <p class="span-2 form__title">{{ isEdit ? 'Update Tag' : 'Create New Tag' }}</p>

        <v-text-field
            v-model="tag.name"
            dense
            :rules="[required('Tag Name is required!')]"
            label="Tag Name"
            name="Tag name"
            outlined
            class="span-2"
        />

        <v-autocomplete
            :items="categories"
            v-model="tag.categories"
            @change="filterSubCategories"
            item-text="name"
            item-value="_id"
            dense
            class="flex-grow-1 span-2"
            :loading="loading"
            :rules="[requiredArray('At least one category must be selected!')]"
            label="Choose Categories"
            multiple
            name="Category"
            outlined
            small-chips
        />

        <v-autocomplete
            :items="subcategories"
            v-model="tag.subcategories"
            item-text="name"
            item-value="_id"
            dense
            class="flex-grow-1 span-2"
            :loading="loading"
            :rules="[requiredArray('At least one subcategory must be selected!')]"
            label="Choose Subcategory"
            multiple
            name="Sub-category"
            outlined
            small-chips
        />

        <v-checkbox
            dense
            hide-details="auto"
            label="Active"
            name="status"
            class="span-2"
            v-model="tagStatus"
        >
        </v-checkbox>

        <loading-dialog v-model="loading" message="Loading..."/>
      </SimpleForm>
    </v-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import DataTable from '../../components/DataTable';
import {getUser} from '@/utils/local';
import LoadingDialog from '../../components/LoadingDialog';
import SimpleForm from '../../components/Form';
import {required,requiredArray} from '@/utils/validators';
import {TagsService} from "../../services/tags-service";

export default {
  components: {DataTable, LoadingDialog, SimpleForm},
  data: () => ({
    checked: true,
    unchecked: false,
    items: [],
    loadingDialog: false,
    loading: false,
    service: new TagsService(),
    headers: [
      {
        text: 'Tag',
        value: 'name',
        sortable: true,
      },
      {
        text: 'Category',
        value: 'categories',
        sortable: true,
      },
      {
        text: 'Subcategory',
        value: 'subcategories',
        sortable: true,
      },
      {
        text: 'Active',
        value: 'status',
      },
      {
        text: 'Created At',
        value: 'createdAt'
      },
    ],

    categories: [],
    allSubCategories: [],
    subcategories: [],
    tagStatus: false,
    selectedTagId: '',
    tag: {
      name: '',
      categories: [],
      subcategories: [],
      status: ''
    },

    isEdit: false,
    showTagModal: false,
  }),

  methods: {
    getUser,
    required,
    requiredArray,
    formatDate(date) {
      return dayjs(date).format('D MMM YYYY - hh:mm a');
    },
    async edit(item) {
      if (!item) return;
      this.selectedTagId = item._id;
      this.isEdit = true;
      this.loading = true;
      this.tag = await this.service.fetchOne(this.selectedTagId);
      this.tagStatus = this.tag.status === 'Active';
      this.filterSubCategories()
      this.loading = false;
      this.showTagModal = true;
    },
    async loadData() {
      this.categories = await this.service.fetchAllCategories();
      this.allSubCategories = await this.service.fetchAllSubCategories();
      this.filterSubCategories();
      return this.service.fetchAll();
    },

    filterSubCategories() {
      this.subcategories = this.allSubCategories.filter((subCat) => {
        return subCat.categories.filter((cat) => {
          return this.tag.categories.includes(cat._id)
        }).length > 0
      })

      if (this.tag.subcategories?.length > 0) {
        this.tag.subcategories = this.tag.subcategories?.filter((subCategory) => {
          return this.subcategories.filter((subCat) => {
            return subCat._id === subCategory
          }).length > 0
        })
      }
    },

    async openSubCategoriesForm() {
      this.isEdit = false;
      this.showTagModal = true;
    },

    closeTagForm() {
      document.getElementById('refresh').click();
      this.$refs.tagForm.resetForm();
      this.showTagModal = false;
      this.isEdit = false;
    },

    categoryName(categoryId) {
      return this.categories.filter((cat) => {
        return cat._id === categoryId
      })[0].name;
    },

    subCategoryName(subcategoryId) {
      return this.allSubCategories.filter((sub_cat) => {
        return sub_cat._id === subcategoryId
      })[0].name;
    },

    async submit(context) {
      if (this.isEdit) {
        context.changeLoadingMessage('Updating Tag');
        this.loading = true;
        try {
          this.tag.status = this.tagStatus ? 'Active' : 'Inactive';

          let data = {...this.tag}

          await this.service.update(this.selectedTagId, data);
          this.closeTagForm();
          this.loading = false;
          this.isEdit = false;
          return true
        } catch (e) {
          context.reportError({
            'title': 'Error while Updating Tag',
            'description': e?.response?.data?.message || e?.data?.message || 'Some error occurred'
          })
          this.loading = false;
          return false
        }
      } else {
        context.changeLoadingMessage('Creating New Tag');

        this.loading = true;
        try {
          this.tag.status = this.tagStatus ? 'Active' : 'Inactive';
          let data = {...this.tag}

          await this.service.create(data);
          this.closeTagForm();
          this.loading = false;
          return true
        } catch (e) {
          context.reportError({
            'title': 'Error while creating Tag',
            'description': e?.response?.data?.message || e?.data?.message || 'Some error occurred'
          })
          this.loading = false;
          return false
        }
      }
    },
  }
};
</script>

<style scoped></style>
