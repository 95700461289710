import {getUser} from "@/utils/local";
import TagsView from "@/views/tags/TagsView";

export const tagsRoutes = [
    getUser()?.scopes.includes('tags:view') ? {
        path: '/tags',
        name: 'Tags',
        component: TagsView
    } : null,
];

export const tagsRouter = tagsRoutes.filter(function (x) {
    return x !== null;
})

