import axios from 'axios';

export class ReportsService {
    async fetchAll() {
        return (await axios.get('feedback')).data;
    }

    async fetchOne(id) {
        return (await axios.get('feedback/' + id)).data;
    }

    async delete(item) {
        return (await axios.delete('feedback/' + item._id)).data;
    }
}
