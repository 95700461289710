import axios from 'axios';

export class SubCategoriesService {
  async fetchAllActiveSubCategories() {
    return (await axios.get('/subcategory/filter?status=Active')).data;
  }

  async fetchAllCategories() {
    return (await axios.get('/category?status=Active')).data;
  }

  async fetchAll() {
    return (await axios.get('/subcategory/filter')).data;
  }

  async fetchOne(id) {
    return (await axios.get('/subcategory/' + id)).data;
  }

  async update(id, data) {
    return (await axios.patch('/subcategory/' + id, data)).data;
  }

  async create(data) {
    return (await axios.post('/subcategory', data)).data;
  }

  async delete(item) {
    return (await axios.delete('/subcategory/' + item._id)).data;
  }
}
