<template>
  <SimpleForm :onSubmit="submit" @done="$router.back()">
    <p class="span-2 form__title">{{ 'Create New Coupon' }}</p>
    <v-text-field
        v-model="coupon.name"
        dense
        :rules="[required('A name must be provided')]"
        label="Coupon Name"
        outlined
        class="span-2"
    />
    <v-text-field
        v-model="coupon.promo_code"
        dense
        :rules="[required('A promo code must be provided')]"
        label="Coupon Promo Code"
        outlined
        class="span-2"
    />
    <v-text-field
        v-model="coupon.discount"
        dense
        :rules="[coupon.type === 1 ? requiredPercentage() : requiredV2]"
        label="Discount"
        outlined
    />
    <v-select
        v-model="coupon.type"
        dense
        outlined
        :rules="[required('A discount type must be selected')]"
        label="Discount Type"
        :items="discounts"
    />
    <v-text-field
        v-model="coupon.max_redemptions"
        dense
        :rules="[coupon.max_redemptions ? requiredV3 : true]"
        label="Max Redemptions (Optional)"
        hint="Will have no restrictions if left empty."
        persistent-hint
        type="number"
        outlined
    />
    <v-select
        v-model="coupon.duration"
        dense
        outlined
        :rules="[required('A duration must be selected')]"
        label="Coupon Duration"
        :items="durations"
    />

    <loading-dialog v-model="loading" message="Fetching Coupon Data"/>

  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import LoadingDialog from '../../components/LoadingDialog';
import {required, requiredV2, requiredPercentage, requiredV3} from '@/utils/validators';
import {CouponsService} from "@/services/coupons-service";

export default {
  name: 'Form',
  components: {LoadingDialog, SimpleForm},

  data: () => ({
    loading: false,
    service: new CouponsService(),
    coupon: {
      type: 1,
      duration: 'once',
    },
    discounts: [{text: 'Percentage (%)', value: 1}, {text: 'Amount ($)', value: 2}],
    durations: [{text: 'One Time', value: 'once'}, {text: 'Life Time', value: 'forever'}],
    errors: [],
  }),

  methods: {
    required,
    requiredV2,
    requiredV3,
    requiredPercentage,
    preCheck(context) {
      return context.validate();
    },
    async submit(context) {
      if (this.preCheck(context)) {
        try {
          context.changeLoadingMessage('Creating A New Coupon');
          if(this.coupon.max_redemptions === '') {
            delete this.coupon.max_redemptions
          }
          await this.service.create(this.coupon);
          return true
        } catch (e) {
          context.reportError({
            title: 'Error occurred while creating Coupon',
            description: e.toString()
          });
          return false
        }
      }
    },
  }
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}
</style>
