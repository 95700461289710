<template>
  <div>
    <data-table
        :allow-add="getUser() ? getUser().scopes.includes('posts:new') : false"
        :delete-handler="getUser() && getUser().scopes.includes('posts:delete') ?  service.delete  : null"
        :edit-handler="getUser() && getUser().scopes.includes('posts:edit') ?  edit  : null"
        :headers="headers"
        :loader="loadData"
        title="All Posts"
        @done="$router.back()"
        @add-new="addNew"
    >
      <template #primary-action>
        <v-btn v-if="getUser() ? getUser().scopes.includes('posts:export') : false" class="mr-3"
               color="primary" elevation="0" @click="exportPosts">Export
        </v-btn>
      </template>

      <template v-slot:secondary-actions>
        <v-menu v-model="filter" :close-on-click="false" :close-on-content-click="false" min-width="200" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon
                   style="margin-left: 10px"
                   v-bind="attrs"
                   v-on="on">
              <v-icon>mdi-filter</v-icon>
            </v-btn>
          </template>
          <v-card class="pa-4">
            <div class="d-flex justify-space-between mb-2">
              <p class="ma-0">Filter</p>
              <v-icon @click="filter = false">mdi-close</v-icon>
            </div>
            <v-form ref="form">
              <v-text-field
                  v-model="filters.text"
                  class="flex-grow-1 my-2"
                  clearable
                  dense
                  hide-details
                  label="Search Posts"
                  outlined
                  prepend-inner-icon="mdi-magnify"
                  @change="filters.page = -1"
                  @keyup="filters.page = -1"
                  @keypress.enter="loadData"
              >
              </v-text-field>

              <v-autocomplete
                  v-model="filters.categories"
                  :items="allCategoriesOptions"
                  :loading="categoriesLoading"
                  class="flex-grow-1 my-2"
                  dense
                  hide-details
                  item-text="name"
                  item-value="_id"
                  label="Choose Category"
                  multiple
                  name="Category"
                  outlined
                  required
                  small-chips
                  @change="filterSubCategories"
              ></v-autocomplete>

              <v-autocomplete
                  v-model="filters.subcategories"
                  :items="allSubCategoriesOptions"
                  :loading="subCategoriesLoading"
                  class="flex-grow-1 my-2"
                  dense
                  hide-details
                  item-text="name"
                  item-value="_id"
                  label="Choose Sub Category"
                  multiple
                  name="Category"
                  outlined
                  required
                  small-chips
                  @change="filterTags"
              ></v-autocomplete>

              <v-autocomplete
                  v-model="filters.tags"
                  :items="allTagsOptions"
                  class="flex-grow-1 my-2"
                  dense
                  hide-details
                  item-text="name"
                  item-value="_id"
                  label="Choose Tags"
                  multiple
                  name="Category"
                  outlined
                  required
                  small-chips
              ></v-autocomplete>

              <div class="d-flex" style="gap:10px">
                <v-btn class="flex-grow-1" elevation="0" @click="clearFilter">
                  Clear
                </v-btn>
                <v-btn class="flex-grow-1" color="primary" elevation="0" @click="applyFilters">
                  Filter
                </v-btn>
              </div>
            </v-form>
          </v-card>
        </v-menu>
      </template>

      <template v-slot:extra-actions="{item}">
        <v-icon v-if="item.media && item.media.length > 0" color="purple lighten-2" small
                @click="openGallery(item)">mdi-image-multiple
        </v-icon>
      </template>
      <template #hashtags="{ item }">
        <v-chip
            v-for="(chip, key) in item.hashtags" :key="key"
            class="ma-1"
            color="primary"
            small
            text-color="white"
        >
          #{{ chip }}
        </v-chip>
      </template>
      <template #categories="{ item }">
        <div v-if="item.categories.length > 0">
          <v-chip
              v-for="(chip, key) in item.categories" :key="key"
              class="ma-1"
              color="primary"
              small
              text-color="white"
          >
            {{ chip.name }}
          </v-chip>
        </div>
        <div v-else>
          N/A
        </div>
      </template>
      <template #subcategories="{ item }">
        <div v-if="item.subcategories.length > 0">
          <v-chip
              v-for="(chip, key) in item.subcategories" :key="key"
              class="ma-1"
              color="primary"
              small
              text-color="white"
          >
            {{ chip.name }}
          </v-chip>
        </div>
        <div v-else>
          N/A
        </div>
      </template>
      <template #tags="{ item }">
        <div v-if="item.tags.length > 0">
          <v-chip
              v-for="(chip, key) in item.tags" :key="key"
              class="ma-1"
              color="primary"
              small
              text-color="white"
          >
            {{ chip.name }}
          </v-chip>
        </div>
        <div v-else>
          N/A
        </div>
      </template>
      <template #createdAt="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>
      <template #isActive="{ item }">
        <v-checkbox v-model="item.isActive" readonly/>
      </template>
    </data-table>
    <v-dialog v-model="gallery" width="50%">
      <v-card v-if="post" class="pa-2" style="height: 100%;max-height:70vh;position: relative">
        <v-avatar class="elevation-1" color="white" size="32"
                  style="position: absolute; top: 10px; right: 10px; z-index: 1">
          <v-icon color="black" @click="gallery = false">mdi-close</v-icon>
        </v-avatar>
        <v-carousel :hide-delimiters="post.mediaType === 'Video'"
                    :show-arrows="post.media.length > 1" :show-arrows-on-hover="post.media.length > 1">
          <v-carousel-item
              v-for="(item,i) in post.media"
              :key="i"
              reverse-transition="fade-transition"
              transition="fade-transition"
          >
            <img v-if="item.type.includes('image')" :alt="item.name" :src="item.url" height="100%" width="100%"/>
            <video v-else :poster="item.thumbnail.url" :src="item.url" controls height="100%" width="100%"/>
          </v-carousel-item>
        </v-carousel>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import {PostsService} from "../../services/posts-service";
import DataTable from '../../components/DataTable';
import {capitalizeFirstLetter, getUser, resolveInitial} from '@/utils/local';
import {CategoriesService} from "../../services/categories-service";
import {SubCategoriesService} from "../../services/sub-categories-service";
import {TagsService} from "../../services/tags-service";

export default {
  components: {DataTable},

  data: () => ({
    items: [],
    loadingDialog: false,
    loading: false,
    service: new PostsService(),
    categoryService: new CategoriesService(),
    subCategoryService: new SubCategoriesService(),
    tagsService: new TagsService(),
    dialog: {},
    post: null,
    gallery: false,
    filter: false,

    filters: {
      categories: [],
      subcategories: [],
      tags: [],
      page: -1,
      text: null,
    },

    headers: [
      {
        text: 'Caption',
        value: 'description',
        width: '30%'
      },
      {
        text: 'Categories',
        value: 'categories',
        width: '20%'
      },
      {
        text: 'Sub-categories',
        value: 'subcategories',
        width: '20%'
      },
      {
        text: 'Tags',
        value: 'tags',
        width: '20%'
      },
      {
        text: 'Hashtags',
        value: 'hashtags',
        width: '20%'
      },
      {
        text: 'Active',
        value: 'isActive',
      },
      {
        text: 'Created At',
        value: 'createdAt',
        width: '10%'
      },
    ],

    // all categories from database
    allCategories: [],
    allCategoriesOptions: [],
    categoriesLoading: false,

    // all categories from database
    allSubCategories: [],
    allSubCategoriesOptions: [],
    subCategoriesLoading: false,

    // all categories from database
    allTags: [],
    allTagsOptions: [],
    tagsLoading: false,
  }),

  async created() {
    const data = await this.service.fetchAll()
    for (let i = 0; i <= data.length; i++) {
      this.dialog['dialog_' + data[i]] = true
    }
  },

  async mounted() {
    //calling all categories
    await this.loadCategories();
    this.allCategoriesOptions = this.allCategories;

    // calling all sub-categories
    await this.loadSubCategories();

    await this.loadData();
  },


  methods: {
    getUser,
    resolveInitial,
    capitalizeFirstLetter,
    formatDate(date) {
      return dayjs(date).format('D MMM YYYY - hh:mm a');
    },
    addNew() {
      this.$router.push('/post');
    },
    edit(item) {
      // logic for opening new edit page in new tab
      let routeData = this.$router.resolve({name: 'NewPost', query: {id: item._id}});
      window.open(routeData.href, '_blank');
      // this.$router.push(`/post/?id=${item._id}`);
    },

    async loadData() {
      if(this.filters.text || this.filters.categories.length > 0 || this.filters.subcategories.length > 0 || this.filters.tags.length > 0) {
        this.filter = false;
        this.filters.all = true;
        this.items = await this.service.fetchAllFiltered(this.filters);
        return this.items;
      } else {
        this.items = await this.service.fetchAll();
        return this.items;
      }
    },

    async applyFilters() {
      await this.loadData();
      document.getElementById('refresh').click();
    },

    async loadCategories() {
      this.categoriesLoading = true;
      this.allCategories = await this.categoryService.fetchAllActiveCategories()
      this.categoriesLoading = false;
    },

    async loadSubCategories() {
      this.subCategoriesLoading = true;
      this.allSubCategories = await this.subCategoryService.fetchAllActiveSubCategories();
      this.subCategoriesLoading = false;
    },

    filterSubCategories() {
      this.subCategoriesLoading = true;
      this.allSubCategoriesOptions = this.allSubCategories.filter((subCat) => {
        return subCat.categories.filter((cat) => {
          return this.filters.categories.includes(cat._id)
        }).length > 0
      })

      if (this.filters.subcategories?.length > 0) {
        this.filters.subcategories = this.filters.subcategories?.filter((subCategory) => {
          return this.allSubCategoriesOptions.filter((subCat) => {
            return subCat._id === subCategory
          }).length > 0
        })
      }

      this.filters.page = -1
      this.subCategoriesLoading = false

      this.filterTags();
    },

    async filterTags() {
      this.tagsLoading = true;
      this.allTags = await this.tagsService.fetchAllActiveTags(this.filters)
      this.filters.page = -1
      this.allTagsOptions = this.allTags
      this.allTagsOptions = this.allTags
      this.tagsLoading = false;
    },

    clearFilter() {
      this.filters.categories = [];
      this.filters.subcategories = [];
      this.filters.tags = [];
      this.filters.page = -1;
      this.filters.text = null;
      this.$refs.form.reset()
      this.filter = false;

      if(this.filters.all) {
        this.filters.all = false;
      }

      this.filters = {...this.filters};
      this.loadData()
      document.getElementById('refresh').click();
    },
    openGallery(post) {
      this.gallery = true
      this.post = post
    },
    exportPosts() {
      this.loading = true
      const csv = this.toCSV(this.items)
      const name = 'posts.csv'
      const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'})
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", name);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      this.loading = false
    },
    toCSV(items) {
      const array = typeof items != 'object' ? JSON.parse(items) : items;
      let str = 'Caption,Categories,Sub-Categories,Tags,HashTags,IsActive,ImageUrl\r\n';
      for (let i = 0; i < array.length; i++) {
        let line = '';

        // caption
        if (array[i].description) {
          array[i].description = array[i].description.replaceAll(',', ' ').replaceAll('\n', ' ')
          line += array[i].description
        }

        // categories
        line += ',' + this.getArrayValueIntoString(array[i], 'categories');

        // sub-categories
        line += ',' + this.getArrayValueIntoString(array[i], 'subcategories');

        // tags
        line += ',' + this.getArrayValueIntoString(array[i], 'tags');

        // hash-tags
        if (array[i].hashtags && array[i].hashtags.length > 0) {
          line += ',' + this.getArrayValueIntoString(array[i], 'hashtags');
        } else {
          line += ', N/A'
        }

        // isActive
        line += array[i].isActive ? ', Yes' : ', No'

        // image-url
        line += ',' + this.getArrayValueIntoString(array[i], 'media');

        str += line + '\r\n'
      }
      return str
    },
    getArrayValueIntoString(item, module) {
      let str = '';
      switch (module) {
        case 'categories':
          for (let j = 0; j < item.categories.length; j++) {
            if (item.categories[j]) {
              str += item.categories[j].name.replaceAll(',', ' ') + ' - '
            }
          }
          break;
        case 'subcategories':
          for (let j = 0; j < item.subcategories.length; j++) {
            if (item.subcategories[j]) {
              str += item.subcategories[j].name.replaceAll(',', ' ') + ' - '
            }
          }
          break;
        case 'tags':
          for (let t = 0; t < item.tags.length; t++) {
            if (item.tags[t]) {
              str += item.tags[t].name.replaceAll(',', ' ') + ' - '
            }
          }
          break;
        case 'hashtags':
          for (let ht = 0; ht < item.hashtags.length; ht++) {
            if (item.hashtags[ht]) {
              str += item.hashtags[ht].replaceAll(',', ' ') + ' - '
            }
          }
          break;
        case 'media':
          for (let img = 0; img < item.media.length; img++) {
            if (item.media[img]) {
              str += item.media[img].url + ' , '
            }
          }
          break;
        default:
          break;
      }

      return str.slice(0, -3);
    }
  }
};
</script>

<style scoped></style>
