import CategoriesView from "./CategoriesView";
import {getUser} from "../../utils/local";

export const categoriesRoutes = [
    getUser()?.scopes.includes('categories:view') ? {
        path: '/categories',
        name: 'Categories',
        component: CategoriesView
    } : null,
];

export const categoriesRouter = categoriesRoutes.filter(function (x) {
    return x !== null;
})

