<template>
  <data-table
      :loader="loadData"
      :headers="headers"
      title="Customers"
      :allow-add="false"
      :view-handler="showCustomer"
      @done="$router.back()"
  >
    <template #primary-action>
      <v-btn v-if="getUser() ? getUser().scopes.includes('customers:export') : false" @click="exportCustomers"
             elevation="0" color="primary" class="mr-3">Export
      </v-btn>

      <div class="text-center">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                v-bind="attrs"
                v-on="on"
            >
              <v-icon class="v-btn__pre-icon" small>mdi-filter</v-icon>&nbsp; Status
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(status, key) in status_filters_option" :key="key">
              <v-list-item-title style="cursor: pointer" @click="applyFilterOnStatus(status.value)">{{ status.text }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>

    <template #name="{ item }">
      <div class="d-flex align-center justify-start">
        <v-avatar v-if="item.image" size="42"
                  style="border: 1px solid #EF3736">
          <img v-if="item.image.name.includes('http')"
               style="max-width: 100%;" :src="item.image.name" referrerpolicy="no-referrer"/>
          <img v-else :src="$axios.defaults.baseURL + 'uploads/' + item.image.name"/>
        </v-avatar>
        <v-avatar v-else size="42" style="border: 1px solid #EF3736" color="primary" class="white--text">
          <span>{{ resolveInitial((item.name || '')) }}</span>
        </v-avatar>
        <p class="ma-0 ml-2">{{ (item.name || '') }}</p>
      </div>
    </template>

    <template #phone="{ item }">
      <span v-if="item.phone">{{ item.phone }}</span>
      <span v-else>N/A</span>
    </template>

    <template #subscription="{ item }">
      <span>
        {{ getSubscriptionStatus(item) }}
      </span>
    </template>

    <template #created_at="{ item }">
      {{ formatDate(item.createdAt) }}
    </template>
  </data-table>
</template>

<script>
import dayjs from 'dayjs';
import DataTable from '../../components/DataTable';
import {resolveInitial, getUser, capitalizeFirstLetter} from '@/utils/local';
import {UsersService} from "@/services/users-service";

export default {
  components: {DataTable},

  data: () => ({
    items: [],
    displayItems: [],
    status: null,
    loading: false,
    service: new UsersService(),
    status_filters_option: [
      {text: 'All', value: ''},
      {text: 'Incomplete', value: 'incomplete'},
      {text: 'Incomplete Expired', value: 'incomplete_expired'},
      {text: 'Trialing', value: 'trialing'},
      {text: 'Active', value: 'active'},
      {text: 'Past Due', value: 'past_due'},
      {text: 'Cancel(in process)', value: 'in-cancellation'},
      {text: 'Canceled', value: 'canceled'},
      {text: 'Unpaid', value: 'unpaid'},
    ],
    headers: [
      {
        text: 'Name',
        value: 'name',
        sortable: false,
      },
      {
        text: 'Email',
        value: 'username',
        sortable: false,
      },
      {
        text: 'Phone',
        value: 'phone',
        sortable: false,
      },
      {
        text: 'Status',
        value: 'subscription',
        sortable: false,
        width: 180,
      },
      {
        text: 'Subscribed',
        value: 'created_at',
        sortable: false,
        width: 180,
      },
    ]
  }),

  methods: {
    getUser,
    resolveInitial,
    capitalizeFirstLetter,
    formatDate(date) {
      return dayjs(date).format('D MMM YYYY - hh:mm a');
    },

    applyFilterOnStatus(_status) {
      this.status = _status;
      document.getElementById("refresh").click()
    },

    showCustomer(item) {
      this.$router.push(`/customer-details?id=${item._id}`);
    },

    async loadData() {
      if (this.status) {
        if (this.status === 'in-cancellation') {
          this.displayItems = this.items.filter((item) => item?.subscription?.status === 'active' && item?.subscription?.cancel_at_period_end)
        } else {
          this.displayItems = this.items.filter((item) => item?.subscription?.status === this.status)
        }
      } else {
        this.items = await this.service.fetchAllCustomers();
        this.displayItems = this.items;
      }
      return this.displayItems;
    },

    exportCustomers() {
      this.loading = true
      const csv = this.toCSV(this.displayItems)
      console.log(this.displayItems, 'items')
      console.log(csv, 'csv')
      const name = 'customers.csv'
      const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'})
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", name);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      this.loading = false
    },
    toCSV(items) {
      const array = typeof items != 'object' ? JSON.parse(items) : items;
      let str = 'Name,Email,Phone,Status,Subscribed,Organization Name,Verified,Subscription Date,Next Billing Date,Plan,Amount,Cancel at, Cancellation Reason\r\n';
      for (let i = 0; i < array.length; i++) {
        let line = '';
        if (array[i].name) {
          array[i].name = array[i].name.replaceAll(',', ' ')
          line += array[i].name
        }
        line += ',' + array[i].username
        line += ',' + this.getPhone(array[i])
        line += ',' + this.getSubscriptionStatus(array[i])
        line += ',' + this.formatDate(array[i].createdAt)
        line += ',' + this.getOrganizationName(array[i])
        line += ',' + this.isVerified(array[i])
        line += ',' + this.getStartDate(array[i])
        line += ',' + this.getEndDate(array[i])
        line += ',' + this.getPlanId(array[i])
        // line += ',' + this.getCouponCode(array[i])
        line += ',' + this.getAmount(array[i])
        // line += ',' + this.getDiscountInPercent(array[i])
        // line += ',' + this.getDiscountInAmount(array[i])
        line += ',' + this.getCancelAtDate(array[i])
        line += ',' + this.getCancellationReason(array[i])
        str += line + '\r\n'
      }
      return str
    },
    getSubscriptionStatus(item) {
      if (item.subscription && item.subscription.status === 'active' && item.subscription.cancel_at_period_end) {
        return this.capitalizeFirstLetter(item.subscription.status.replace('_', ' ').replace('-', ' ')) + "(in-cancellation)";
      } else if (item.subscription && item.subscription.status !== 'active' && item.subscription.cancel_at_period_end) {
        return this.capitalizeFirstLetter(item.subscription.status);
      } else if (item.subscription && item.subscription.status && !item.subscription.cancel_at_period_end) {
        return this.capitalizeFirstLetter(item.subscription.status);
      } else {
        return 'N/A';
      }
    },
    getPhone(item) {
      if (item.phone) {
        return item.phone;
      } else {
        return 'N/A';
      }
    },
    isVerified(item) {
      if (item.verified) {
        return 'Verified';
      } else {
        return 'Not Verified';
      }
    },
    getOrganizationName(item) {
      return item.organizationName ? item.organizationName.replaceAll(',', ' ') : 'N/A'
    },
    getPlanId(item) {
      return (item?.subscription?.plan?.id) ? this.capitalizeFirstLetter((item?.subscription?.plan.id).replace('-', ' ')) : 'N/A'
    },
    getCouponCode(item) {
      return (item?.subscription?.discount?.coupon) ? item?.subscription?.discount?.coupon?.name.replaceAll(',', ' ') : 'N/A'
    },
    getStartDate(item) {
      return (item?.subscription?.current_period_start)
          ? this.getDateFromTimestamp(item.subscription.current_period_start).replaceAll(',', ' ')
          : 'N/A'
    },
    getEndDate(item) {
      return (item?.subscription?.current_period_end)
          ? this.getDateFromTimestamp(item.subscription.current_period_end).replaceAll(',', ' ')
          : 'N/A'
    },
    getCancelAtDate(item) {
      return (item?.subscription?.cancel_at)
          ? this.getDateFromTimestamp(item.subscription.cancel_at).replaceAll(',', ' ')
          : 'N/A'
    },
    getDateFromTimestamp(_timestamp) {
      let date = new Date(_timestamp * 1000);
      return this.formatDate(date).replaceAll(',', ' ')
    },
    getAmount(item) {
      return (item?.subscription?.plan?.amount) ? '$' + (item?.subscription?.plan.amount / 100).toFixed(2).replaceAll(',', ' ') : 'N/A'
    },
    getDiscountInPercent(item) {
      return (item?.subscription?.discount?.coupon?.percent_off) ? item.subscription.discount.coupon.percent_off + '%'.replaceAll(',', ' ') : 'N/A'
    },

    getDiscountInAmount(item) {
      if(item?.subscription?.discount?.coupon?.percent_off) {
        return  '$' + this.calculateDiscountAmount(item?.subscription?.plan?.amount, item?.subscription?.discount?.coupon?.percent_off).replaceAll(',', ' ');
      } else {
        return item?.subscription?.discount?.coupon?.amount_off ? '$' + (item?.subscription?.discount?.coupon?.amount_off / 100).replaceAll(',', ' ') : '$0';
      }
    },
    getCancellationReason(item) {
      return (item?.subscription?.cancel_at_period_end && item.subscription_cancellation_reason) ? item.subscription_cancellation_reason.replaceAll(',', ' ') : 'N/A'
    },
    calculateDiscountAmount(_amount, _percent) {
      return ((_percent * (_amount / 100)) / 100).toFixed(2);
    }
  }
}

</script>

<style scoped></style>