import axios from 'axios';

export class CategoriesService {
  async fetchAllActiveCategories() {
    return (await axios.get('/category?status=Active')).data;
  }

  async fetchAll() {
    return (await axios.get('/category')).data;
  }

  async fetchOne(id) {
    return (await axios.get('/category/' + id)).data;
  }

  async update(id, data) {
    return (await axios.patch('/category/' + id, data)).data;
  }

  async create(data) {
    return (await axios.post('/category', data)).data;
  }

  async delete(item) {
    return (await axios.delete('/category/' + item._id)).data;
  }
}
