<template>
  <div>
    <data-table
        :loader="loadData"
        :headers="headers"
        title="Coupons"
        :allow-add="getUser() ? getUser().scopes.includes('coupons:new') : false"
        @add-new="addNew"
        @done="$router.back()"
        :delete-handler="getUser() && getUser().scopes.includes('coupons:delete') ?  service.delete  : null"
    >
      <template #name="{ item }">
        <span>{{ item.coupon.name }}</span>
      </template>
      <template #type="{ item }">
        <span>{{ item.coupon.percent_off ? 'Percentage (%)' : 'Amount ($)' }}</span>
      </template>
      <template #discount="{ item }">
        <span>{{ item.coupon.percent_off || item.coupon.amount_off / 100 }}</span>
      </template>
      <template #duration="{ item }">
        <span>{{ item.coupon.duration === 'once' ? 'One Time' : 'Life Time' }}</span>
      </template>
      <template #max_redemptions="{ item }">
        <span v-if="item.coupon.max_redemptions">{{ item.coupon.max_redemptions }}</span>
        <v-icon v-else size="18">mdi-infinity</v-icon>
      </template>
      <template #active="{ item }">
        <v-checkbox readonly v-model="item.active" />
      </template>
    </data-table>

    <loading-dialog v-model="loadingDialog" message="Loading..."/>
  </div>
</template>

<script>
import DataTable from '../../components/DataTable';
import LoadingDialog from "../../components/LoadingDialog";
import {getUser} from '@/utils/local';
import {CouponsService} from "@/services/coupons-service";


export default {
  components: {LoadingDialog, DataTable},

  data: () => ({
    loading: false,
    loadingDialog: false,
    service: new CouponsService(),

    headers: [
      {
        text: 'Coupon Name',
        value: 'name',
        sortable: false
      },
      {
        text: 'Promo Code',
        value: 'code',
        sortable: false
      },
      {
        text: 'Discount Type',
        value: 'type'
      },
      {
        text: 'Discount',
        value: 'discount'
      },
      {
        text: 'Duration',
        value: 'duration'
      },
      {
        text: 'Max Redemptions',
        value: 'max_redemptions'
      },
      {
        text: 'Status',
        value: 'active',
        sortable: false,
      },
    ]
  }),

  methods: {
    getUser,
    addNew() {
      this.$router.push('/coupon');
    },
    loadData() {
      return this.service.fetchAll();
    },
  }
};
</script>

<style lang="sass" scoped>
.chip
  font-weight: 500
  padding: 5px 10px
  border-radius: 50px
  background: lightgray
</style>
